import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  Edit,
  ArrowLeft,
  Calendar,
  User as UserIcon,
  Users,
  Award,
  Clock,
  Activity,
  FileText,
  ChevronRight,
  Loader,
  CheckSquare,
  File,
  Plus,
} from "lucide-react";
import {
  fetchTreatmentPlan,
  selectCurrentTreatmentPlan,
  selectTreatmentPlansLoading,
  selectTreatmentPlansError,
  updateTreatmentPlan,
} from "../../store/slices/treatmentPlanSlice";
import {
  fetchPatient,
  selectCurrentPatient,
} from "../../store/slices/patientSlice";
import { createTask, selectAllTasks } from "../../store/slices/taskSlice";
import {
  createMeeting,
  selectAllMeetings,
} from "../../store/slices/meetingSlice";
import {
  uploadDocumentWithFile,
  selectAllDocuments,
} from "../../store/slices/documentSlice";
import { AppDispatch, RootState } from "../../store";
import { TreatmentPlan } from "../../services/treatmentPlanService";
import { Task } from "../../services/taskService";
import { TaskData } from "../../services/taskService";
import { Patient } from "../../store/slices/patientSlice";
import { User } from "../../store/slices/userSlice";
import { Meeting, MeetingData } from "../../services/meetingService";
import { Document } from "../../services/documentService";
import { Collaborator } from "../../services/treatmentPlanService";
import { selectCurrentUser } from "../../store/slices/authSlice";

const TreatmentPlanDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const treatmentPlan = useSelector(selectCurrentTreatmentPlan);
  const loading = useSelector(selectTreatmentPlansLoading);
  const error = useSelector(selectTreatmentPlansError);
  const patient = useSelector(selectCurrentPatient);
  const tasks = useSelector(selectAllTasks);
  const meetings = useSelector(selectAllMeetings);
  const documents = useSelector(selectAllDocuments);
  const currentUser = useSelector(selectCurrentUser);

  const [isAddingTask, setIsAddingTask] = useState(false);
  const [newTask, setNewTask] = useState<TaskData>({
    title: "",
    description: "",
    dueDate: "",
    status: "pending",
    priority: "medium",
  });

  const [isAddingMeeting, setIsAddingMeeting] = useState(false);
  const [newMeeting, setNewMeeting] = useState<MeetingData>({
    title: "",
    dateTime: "",
    duration: 60,
    isVirtual: false,
    attendeeIds: [],
    patientIds: [],
  });

  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [newDocument, setNewDocument] = useState<File | null>(null);

  useEffect(() => {
    if (id) {
      dispatch(fetchTreatmentPlan(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (treatmentPlan && treatmentPlan.attributes.patient.data.id) {
      dispatch(fetchPatient(treatmentPlan.attributes.patient.data.id));
    }
  }, [dispatch, treatmentPlan]);

  const handleAddTask = async () => {
    if (treatmentPlan) {
      await dispatch(
        createTask({ ...newTask, treatmentPlan: treatmentPlan.id })
      );
      setIsAddingTask(false);
      setNewTask({
        title: "",
        description: "",
        dueDate: "",
        status: "pending",
        priority: "medium",
      });
    }
  };

  const handleAddMeeting = async () => {
    if (treatmentPlan) {
      await dispatch(
        createMeeting({
          ...newMeeting,
          attendeeIds: treatmentPlan.attributes?.collaborators?.data.map((c) => c.id.toString()) || [],
          patientIds: [treatmentPlan.attributes.patient.data.id],
          treatmentPlan: treatmentPlan.id
        })
      );
      setIsAddingMeeting(false);
      setNewMeeting({
        title: "",
        dateTime: "",
        duration: 60,
        isVirtual: false,
        attendeeIds: [],
        patientIds: [],
      });
    }
  };

  const handleUploadDocument = async () => {
    if (treatmentPlan && newDocument) {
      try {
        const documentData = {
          title: newDocument.name,
          type: "other" as
            | "report"
            | "image"
            | "lab_result"
            | "consent"
            | "other",
          patient: treatmentPlan.attributes.patient.data.id,
          treatmentPlan: treatmentPlan.id,
          uploader: currentUser.id,
        };

        await dispatch(
          uploadDocumentWithFile({
            file: newDocument,
            documentData,
          })
        ).unwrap();

        setIsUploadingDocument(false);
        setNewDocument(null);
        // Optionally, refetch the treatment plan or update the local state
      } catch (error) {
        console.error("Failed to upload document:", error);
        // Handle the error (e.g., show an error message to the user)
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  if (error || !treatmentPlan) {
    return (
      <div className="text-center py-4 text-red-500">
        Error: {error || "Treatment plan not found"}
      </div>
    );
  }

  const renderPatientSummary = (patient: Patient | null) => {
    if (!patient) return null;
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <UserIcon className="mr-2 text-blue-500" />
          Patient Summary
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">Name</p>
            <p className="font-semibold">{`${patient.attributes.firstName} ${patient.attributes.lastName}`}</p>
          </div>
          <div>
            <p className="text-gray-600">Date of Birth</p>
            <p className="font-semibold">
              {new Date(patient.attributes.dateOfBirth).toLocaleDateString()}
            </p>
          </div>
          <div>
            <p className="text-gray-600">Gender</p>
            <p className="font-semibold">{patient.attributes.gender}</p>
          </div>
          <div>
            <p className="text-gray-600">Contact</p>
            <p className="font-semibold">{patient.attributes.contactInfo}</p>
          </div>
        </div>
        <Link
          to={`/patients/${patient.id}`}
          className="text-blue-500 hover:underline mt-4 inline-block"
        >
          View Full Medical Record <ChevronRight className="inline" size={16} />
        </Link>
      </div>
    );
  };

  const renderProgress = (treatmentPlan: TreatmentPlan) => {
    console.log(treatmentPlan)
    const completedTasks =
      treatmentPlan.attributes.tasks?.data.filter((task) => task.status === "completed")
        .length || 0;
    const totalTasks = treatmentPlan.attributes.tasks?.data.length || 0;
    const progress = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;

    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <Activity className="mr-2 text-blue-500" />
          Treatment Progress
        </h2>
        <div className="mb-4">
          <div className="flex justify-between mb-1">
            <span>Overall Progress</span>
            <span>{progress.toFixed(1)}%</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
        <p className="text-sm text-gray-600">
          Start Date: {new Date(treatmentPlan.attributes.startDate).toLocaleDateString()}
        </p>
        {treatmentPlan.attributes.endDate && (
          <p className="text-sm text-gray-600">
            End Date: {new Date(treatmentPlan.attributes.endDate).toLocaleDateString()}
          </p>
        )}
        <p className="text-sm text-gray-600">
          Status: <span className="font-semibold">{treatmentPlan.attributes.status}</span>
        </p>
      </div>
    );
  };

  const renderTasks = (tasks: any) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold flex items-center">
          <CheckSquare className="mr-2 text-blue-500" />
          Tasks
        </h2>
        <button
          onClick={() => setIsAddingTask(true)}
          className="bg-blue-500 text-white px-3 py-1 rounded-full flex items-center text-sm"
        >
          <Plus size={16} className="mr-1" />
          Add Task
        </button>
      </div>
      <AnimatePresence>
        {isAddingTask && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="bg-gray-100 rounded-lg p-4 mb-4"
          >
            <input
              type="text"
              placeholder="Task Title"
              className="w-full p-2 mb-2 border rounded"
              value={newTask.title}
              onChange={(e) =>
                setNewTask({ ...newTask, title: e.target.value })
              }
            />
            <textarea
              placeholder="Task Description"
              className="w-full p-2 mb-2 border rounded"
              value={newTask.description}
              onChange={(e) =>
                setNewTask({ ...newTask, description: e.target.value })
              }
            />
            <input
              type="date"
              className="w-full p-2 mb-2 border rounded"
              value={newTask.dueDate}
              onChange={(e) =>
                setNewTask({ ...newTask, dueDate: e.target.value })
              }
            />
            <select
              className="w-full p-2 mb-2 border rounded"
              value={newTask.priority}
              onChange={(e) =>
                setNewTask({
                  ...newTask,
                  priority: e.target.value as "low" | "medium" | "high",
                })
              }
            >
              <option value="low">Low Priority</option>
              <option value="medium">Medium Priority</option>
              <option value="high">High Priority</option>
            </select>
            <div className="flex justify-end">
              <button
                onClick={handleAddTask}
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
              >
                Add Task
              </button>
              <button
                onClick={() => setIsAddingTask(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <ul className="space-y-2">
        {tasks.data && tasks.data.length > 0 ? (
          tasks.data.map((task: any) => (
            <li
              key={task.id}
              className="flex items-center justify-between bg-gray-50 p-3 rounded-lg"
            >
              <div>
                <span className="font-semibold">{task.attributes.title}</span>
                <p className="text-sm text-gray-600">
                  {task.attributes.description}
                </p>
                <p className="text-xs text-gray-500">
                  Due: {new Date(task.attributes.dueDate).toLocaleDateString()}
                </p>
              </div>
              <div className="flex items-center">
                <span
                  className={`px-2 py-1 rounded-full text-xs mr-2 ${getPriorityColor(
                    task.attributes.priority
                  )}`}
                >
                  {task.attributes.priority}
                </span>
                <span
                  className={`px-2 py-1 rounded-full text-xs ${getStatusColor(
                    task.attributes.status
                  )}`}
                >
                  {task.attributes.status}
                </span>
              </div>
            </li>
          ))
        ) : (
          <li className="text-gray-500">No tasks added</li>
        )}
      </ul>
    </div>
  );

  const renderMeetings = (meetings: Meeting[]) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold flex items-center">
          <Calendar className="mr-2 text-blue-500" />
          Meetings
        </h2>
        <button
          onClick={() => setIsAddingMeeting(true)}
          className="bg-blue-500 text-white px-3 py-1 rounded-full flex items-center text-sm"
        >
          <Plus size={16} className="mr-1" />
          Schedule Meeting
        </button>
      </div>
      <AnimatePresence>
        {isAddingMeeting && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="bg-gray-100 rounded-lg p-4 mb-4"
          >
            <input
              type="text"
              placeholder="Meeting Title"
              className="w-full p-2 mb-2 border rounded"
              value={newMeeting.title}
              onChange={(e) =>
                setNewMeeting({ ...newMeeting, title: e.target.value })
              }
            />
            <input
              type="datetime-local"
              className="w-full p-2 mb-2 border rounded"
              value={newMeeting.dateTime}
              onChange={(e) =>
                setNewMeeting({ ...newMeeting, dateTime: e.target.value })
              }
            />
            <input
              type="number"
              placeholder="Duration (minutes)"
              className="w-full p-2 mb-2 border rounded"
              value={newMeeting.duration}
              onChange={(e) =>
                setNewMeeting({
                  ...newMeeting,
                  duration: parseInt(e.target.value),
                })
              }
            />
            <label className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={newMeeting.isVirtual}
                onChange={(e) =>
                  setNewMeeting({ ...newMeeting, isVirtual: e.target.checked })
                }
                className="mr-2"
              />
              Virtual Meeting
            </label>
            <div className="flex justify-end">
              <button
                onClick={handleAddMeeting}
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
              >
                Schedule Meeting
              </button>
              <button
                onClick={() => setIsAddingMeeting(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <ul className="space-y-2">
        {meetings.length > 0 ? (
          meetings.map((meeting) => (
            <li
              key={meeting.id}
              className="flex items-center justify-between bg-gray-50 p-3 rounded-lg"
            >
              <div>
                <span className="font-semibold">
                  {meeting.attributes.title}
                </span>
                <p className="text-sm text-gray-600">
                  {new Date(meeting.attributes.dateTime).toLocaleString()}
                </p>
              </div>
              <span
                className={`px-2 py-1 rounded-full text-xs ${
                  meeting.attributes.isVirtual
                    ? "bg-green-100 text-green-800"
                    : "bg-blue-100 text-blue-800"
                }`}
              >
                {meeting.attributes.isVirtual ? "Virtual" : "In-person"}
              </span>
            </li>
          ))
        ) : (
          <li className="text-gray-500">No meetings scheduled</li>
        )}
      </ul>
    </div>
  );

  const renderDocuments = (documents: any) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold flex items-center">
          <File className="mr-2 text-blue-500" />
          Documents
        </h2>
        <button
          onClick={() => setIsUploadingDocument(true)}
          className="bg-blue-500 text-white px-3 py-1 rounded-full flex items-center text-sm"
        >
          <Plus size={16} className="mr-1" />
          Upload Document
        </button>
      </div>
      <AnimatePresence>
        {isUploadingDocument && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="bg-gray-100 rounded-lg p-4 mb-4"
          >
            <input
              type="file"
              onChange={(e) =>
                setNewDocument(e.target.files ? e.target.files[0] : null)
              }
              className="w-full p-2 mb-2 border rounded"
            />
            <div className="flex justify-end">
              <button
                onClick={handleUploadDocument}
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
              >
                Upload
              </button>
              <button
                onClick={() => setIsUploadingDocument(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <ul className="space-y-2">
        {documents.data && documents.data.length > 0 ? (
          documents.data.map((document: any) => (
            <li
              key={document.id}
              className="flex items-center justify-between bg-gray-50 p-3 rounded-lg"
            >
              <span className="font-semibold">{document.attributes.title}</span>
              <div>
                <span className="text-sm text-gray-500 mr-2">
                  {document.attributes.type}
                </span>
                <a
                  href={`http://localhost:1337${document.attributes.file?.data?.attributes?.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View
                </a>
              </div>
            </li>
          ))
        ) : (
          <li className="text-gray-500">No documents uploaded</li>
        )}
      </ul>
    </div>
  );

  const renderTimeline = (treatmentPlan: TreatmentPlan) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Clock className="mr-2 text-blue-500" />
        Timeline
      </h2>
      <ul className="space-y-4 relative before:absolute before:left-2 before:top-2 before:h-full before:w-0.5 before:bg-gray-200">
        {[
          {
            date: new Date(treatmentPlan.attributes.startDate),
            event: "Treatment plan started",
          },
          ...(treatmentPlan.attributes.tasks.data || []).map((task) => ({
            date: new Date(task.dueDate),
            event: `Task due: ${task.title}`,
          })),
          ...(treatmentPlan.attributes.meetings?.data || []).map((meeting) => ({
            date: new Date(meeting.attributes.dateTime),
            event: `Meeting: ${meeting.attributes.title}`,
          })),
          ...(treatmentPlan.attributes.endDate
            ? [
                {
                  date: new Date(treatmentPlan.attributes.endDate),
                  event: "Treatment plan ended",
                },
              ]
            : []),
        ]
          .sort((a, b) => a.date.getTime() - b.date.getTime())
          .map((item, index) => (
            <li key={index} className="ml-6 relative">
              <div className="absolute -left-8 top-1 w-4 h-4 rounded-full bg-blue-500"></div>
              <div className="flex flex-col">
                <span className="text-sm text-gray-500">
                  {item.date.toLocaleDateString()}
                </span>
                <span>{item.event}</span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );

  const renderOwnerInfo = (owner: Collaborator) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Award className="mr-2 text-blue-500" />
        Treatment Plan Owner
      </h2>
      <div className="flex items-center">
        <UserIcon className="mr-2 text-gray-500" size={24} />
        <div>
          <p className="font-semibold">{owner.attributes.firstName+" "+owner.attributes.lastName}</p>
          <p className="text-sm text-gray-600">
          
            Specialties: {owner.attributes.specialties?.data.map((s) => s.attributes.name).join(", ")}
          </p>
        </div>
      </div>
    </div>
  );

  const renderCollaborators = (collaborators: Collaborator[]) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Users className="mr-2 text-blue-500" />
        Collaborators
      </h2>
      <ul className="space-y-4">
        {collaborators.map((collaborator, index) => (
          <li key={index} className="flex items-center">
            <UserIcon className="mr-2 text-gray-500" size={24} />
            <div>
            <p className="font-semibold">{collaborator.attributes.firstName+" "+collaborator.attributes.lastName}</p>
            <p className="text-sm text-gray-600">
                Specialties: {collaborator.attributes.specialties?.data.map((s) => s.attributes.name).join(", ")}
                </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  const renderRecentUpdates = () => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Activity className="mr-2 text-blue-500" />
        Recent Updates
      </h2>
      <ul className="space-y-4">
        <li className="flex items-center">
          <div className="bg-blue-100 rounded-full p-2 mr-3">
            <Activity className="text-blue-500" size={16} />
          </div>
          <div>
            <p className="font-semibold">Progress update</p>
            <p className="text-sm text-gray-600">2 days ago</p>
          </div>
        </li>
        <li className="flex items-center">
          <div className="bg-green-100 rounded-full p-2 mr-3">
            <Users className="text-green-500" size={16} />
          </div>
          <div>
            <p className="font-semibold">New collaborator added</p>
            <p className="text-sm text-gray-600">1 week ago</p>
          </div>
        </li>
      </ul>
    </div>
  );

  const getStatusColor = (status: string) => {
    switch (status) {
      case "planned":
        return "bg-yellow-100 text-yellow-800";
      case "in_progress":
        return "bg-blue-100 text-blue-800";
      case "completed":
        return "bg-green-100 text-green-800";
      case "cancelled":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case "high":
        return "bg-red-100 text-red-800";
      case "medium":
        return "bg-yellow-100 text-yellow-800";
      case "low":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Link
        to="/treatment-plans"
        className="text-blue-500 hover:underline flex items-center mb-6"
      >
        <ArrowLeft size={20} className="mr-2" />
        Back to Treatment Plans
      </Link>

      <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-8">
        <div className="p-6 bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold">
              {treatmentPlan.attributes.title}
            </h1>
            <Link to={`/treatment-plans/${id}/edit`}>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-white text-blue-500 px-4 py-2 rounded-lg flex items-center"
              >
                <Edit className="mr-2" size={20} />
                Edit Plan
              </motion.button>
            </Link>
          </div>
          <div className="mt-2 flex items-center">
            <Calendar className="mr-2" size={16} />
            <span>
              {new Date(
                treatmentPlan.attributes.startDate
              ).toLocaleDateString()}{" "}
              -{" "}
              {treatmentPlan.attributes.endDate
                ? new Date(
                    treatmentPlan.attributes.endDate
                  ).toLocaleDateString()
                : "Ongoing"}
            </span>
          </div>
        </div>
        <div className="p-6">
          <div className="mb-4 flex items-center">
            <div
              className={`px-3 py-1 rounded-full text-sm font-semibold ${getStatusColor(
                treatmentPlan.attributes.status
              )}`}
            >
              {treatmentPlan.attributes.status.charAt(0).toUpperCase() +
                treatmentPlan.attributes.status.slice(1)}
            </div>
          </div>
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FileText className="mr-2 text-blue-500" />
            Plan Description
          </h2>
          <div
            className="text-gray-700 mb-6"
            dangerouslySetInnerHTML={{
              __html: treatmentPlan.attributes.description,
            }}
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-8">
        <div className="col-span-2 space-y-8">
          {renderPatientSummary(patient)}
          {renderProgress(treatmentPlan)}
          {renderTasks(treatmentPlan.attributes.tasks || [])}
          {renderMeetings(treatmentPlan.attributes.meetings?.data || [])}
          {renderDocuments(treatmentPlan.attributes.documents || [])}
          {renderTimeline(treatmentPlan)}
        </div>

        <div className="space-y-8">
          {treatmentPlan.attributes.collaborators &&
            treatmentPlan.attributes.collaborators.data.length > 0 &&
            renderOwnerInfo(treatmentPlan.attributes.collaborators.data[0])}

          {treatmentPlan.attributes.collaborators &&
            treatmentPlan.attributes.collaborators.data.length > 1 &&
            renderCollaborators(
              treatmentPlan.attributes.collaborators.data.slice(1)
            )}
          {renderRecentUpdates()}
        </div>
      </div>
    </div>
  );
};

export default TreatmentPlanDetail;
