import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import medicalRecordService, {
  MedicalHistoryItem,
  VitalSign,
  MedicalEvent,
  Document
} from '../../services/medicalRecordService';
import { Meeting } from '../../services/meetingService';
import { Task } from '../../services/taskService';
import { TreatmentPlan } from '../../services/treatmentPlanService';

interface MedicalRecordState {
  medicalHistory: MedicalHistoryItem[];
  vitalSigns: VitalSign[];
  medicalEvents: MedicalEvent[];
  treatmentPlans: TreatmentPlan[];
  meetings: Meeting[];
  tasks: Task[];
  documents: Document[];
  loading: boolean;
  error: string | null;
}

const initialState: MedicalRecordState = {
  medicalHistory: [],
  vitalSigns: [],
  medicalEvents: [],
  treatmentPlans: [],
  meetings: [],
  tasks: [],
  documents: [],
  loading: false,
  error: null,
};

export const fetchMedicalRecord = createAsyncThunk(
  'medicalRecord/fetchMedicalRecord',
  async (patientId: string, { rejectWithValue }) => {
    try {
      const [
        medicalHistory,
        vitalSigns,
        medicalEvents,
        treatmentPlans,
        meetings,
        tasks,
        documents
      ] = await Promise.all([
        medicalRecordService.getMedicalHistory(patientId),
        medicalRecordService.getVitalSigns(patientId),
        medicalRecordService.getMedicalEvents(patientId),
        medicalRecordService.getTreatmentPlans(patientId),
        medicalRecordService.getMeetings(patientId),
        medicalRecordService.getTasks(patientId),
        medicalRecordService.getDocuments(patientId),
      ]);
      return { medicalHistory, vitalSigns, medicalEvents, treatmentPlans, meetings, tasks, documents };
    } catch (error) {
      return rejectWithValue('Failed to fetch medical record');
    }
  }
);



export const addMedicalHistoryItem = createAsyncThunk(
  'medicalRecord/addMedicalHistoryItem',
  async ({ patientId, item }: { patientId: string; item: Omit<MedicalHistoryItem, 'id'> }, { rejectWithValue }) => {
    try {
      return await medicalRecordService.addMedicalHistoryItem(patientId, item);
    } catch (error) {
      return rejectWithValue('Failed to add medical history item');
    }
  }
);

export const addVitalSign = createAsyncThunk(
  'medicalRecord/addVitalSign',
  async ({ patientId, vitalSign }: { patientId: string; vitalSign: Omit<VitalSign, 'id'> }, { rejectWithValue }) => {
    try {
      return await medicalRecordService.addVitalSign(patientId, vitalSign);
    } catch (error) {
      return rejectWithValue('Failed to add vital sign');
    }
  }
);

export const addMedicalEvent = createAsyncThunk(
  'medicalRecord/addMedicalEvent',
  async ({ patientId, event }: { patientId: string; event: Omit<MedicalEvent, 'id'> }, { rejectWithValue }) => {
    try {
      return await medicalRecordService.addMedicalEvent(patientId, event);
    } catch (error) {
      return rejectWithValue('Failed to add medical event');
    }
  }
);

const medicalRecordSlice = createSlice({
  name: 'medicalRecord',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchMedicalRecord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMedicalRecord.fulfilled, (state, action) => {
        state.loading = false;
        state.medicalHistory = action.payload.medicalHistory;
        state.vitalSigns = action.payload.vitalSigns;
        state.medicalEvents = action.payload.medicalEvents;
        state.treatmentPlans = action.payload.treatmentPlans;
        state.meetings = action.payload.meetings;
        state.tasks = action.payload.tasks;
        state.documents = action.payload.documents;
      })
      .addCase(fetchMedicalRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addMedicalHistoryItem.fulfilled, (state, action) => {
        state.medicalHistory.unshift(action.payload);
      })
      .addCase(addVitalSign.fulfilled, (state, action) => {
        state.vitalSigns.unshift(action.payload);
      })
      .addCase(addMedicalEvent.fulfilled, (state, action) => {
        state.medicalEvents.unshift(action.payload);
      });
  },
});

export default medicalRecordSlice.reducer;