import api from './api';

export interface LoginCredentials {
  identifier: string;
  password: string;
}

export interface RegisterData {
  username: string;
  email: string;
  password: string;
}

const authService = {
  login: async (credentials: LoginCredentials) => {
    const response = await api.post('/api/auth/local', credentials);
    return response.data;
  },

  register: async (data: RegisterData) => {
    const response = await api.post('/api/auth/local/register', data);
    return response.data;
  },

  getCurrentUser: async () => {
    const response = await api.get('/api/users/me');
    return response.data;
  },
};

export default authService;