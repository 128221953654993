import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import patientService from '../../services/patientService';
import { RootState } from '../index';
export interface Patient {
  id: string;
  attributes: {
    externalId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    contactInfo: string;
    createdAt?: string
   };
}

interface PatientState {
  patients: Patient[];
  currentPatient: Patient | null;
  loading: boolean;
  error: string | null;
}

const initialState: PatientState = {
  patients: [],
  currentPatient: null,
  loading: false,
  error: null,
};

export const fetchPatients = createAsyncThunk(
  'patients/fetchPatients',
  async (_, { rejectWithValue }) => {
    try {
      const response = await patientService.getPatients();
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch patients');
    }
  }
);

export const fetchPatient = createAsyncThunk(
  'patients/fetchPatient',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await patientService.getPatient(id);
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch patient');
    }
  }
);

export const createPatient = createAsyncThunk(
  'patients/createPatient',
  async (patientData: Omit<Patient['attributes'], 'id'>, { rejectWithValue }) => {
    try {
      const response = await patientService.createPatient(patientData);
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to create patient');
    }
  }
);

export const updatePatient = createAsyncThunk(
  'patients/updatePatient',
  async ({ id, data }: { id: string; data: Partial<Patient['attributes']> }, { rejectWithValue }) => {
    try {
      const response = await patientService.updatePatient(id, data);
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to update patient');
    }
  }
);

export const deletePatient = createAsyncThunk(
  'patients/deletePatient',
  async (id: string, { rejectWithValue }) => {
    try {
      await patientService.deletePatient(id);
      return id;
    } catch (error) {
      return rejectWithValue('Failed to delete patient');
    }
  }
);

const patientSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatients.fulfilled, (state, action: PayloadAction<Patient[]>) => {
        state.loading = false;
        state.patients = action.payload;
      })
      .addCase(fetchPatients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchPatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatient.fulfilled, (state, action: PayloadAction<Patient>) => {
        state.loading = false;
        state.currentPatient = action.payload;
      })
      .addCase(fetchPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createPatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPatient.fulfilled, (state, action: PayloadAction<Patient>) => {
        state.loading = false;
        state.patients.push(action.payload);
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updatePatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePatient.fulfilled, (state, action: PayloadAction<Patient>) => {
        state.loading = false;
        const index = state.patients.findIndex((patient) => patient.id === action.payload.id);
        if (index !== -1) {
          state.patients[index] = action.payload;
        }
        if (state.currentPatient && state.currentPatient.id === action.payload.id) {
          state.currentPatient = action.payload;
        }
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deletePatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePatient.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.patients = state.patients.filter((patient) => patient.id !== action.payload);
        if (state.currentPatient && state.currentPatient.id === action.payload) {
          state.currentPatient = null;
        }
      })
      .addCase(deletePatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});
export const selectAllPatients = (state: RootState) => state.patients.patients;
export const selectCurrentPatient = (state: RootState) => state.patients.currentPatient;

export const selectPatientById = (state: RootState, patientId: string) =>
  state.patients.patients.find(patient => patient.id === patientId);


export default patientSlice.reducer;