import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import documentService, { Document } from '../../services/documentService';
import { RootState } from '../index';

interface DocumentState {
  documents: Document[];
  currentDocument: Document | null;
  loading: boolean;
  error: string | null;
}

const initialState: DocumentState = {
  documents: [],
  currentDocument: null,
  loading: false,
  error: null,
};

export const fetchDocuments = createAsyncThunk(
  'documents/fetchAll',
  async (patientId: string, { rejectWithValue }) => {
    try {
      return await documentService.getDocuments(patientId);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const uploadDocumentWithFile = createAsyncThunk(
  'documents/uploadWithFile',
  async (data: { 
    file: File; 
    documentData: { 
      title: string;
      type: 'report' | 'image' | 'lab_result' | 'consent' | 'other';
      patient?: string;
      treatmentPlan?: string;
      uploader?: string;
    }
  }, { dispatch, rejectWithValue }) => {
    try {
      const uploadedFile = await documentService.uploadFile(data.file);
      const documentData = {
        ...data.documentData,
        file: uploadedFile.id,
      };
      const document = await documentService.createDocument(documentData);
      
      // Dispatch the refreshDocument action to get the updated document data
      await dispatch(refreshDocument(document.id));
      
      return document;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  'documents/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await documentService.deleteDocument(id);
      return id;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
export const refreshDocument = createAsyncThunk(
  'documents/refresh',
  async (id: string, { rejectWithValue }) => {
    try {
      const document = await documentService.getDocument(id);
      return document;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);




const documentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDocuments.fulfilled, (state, action: PayloadAction<Document[]>) => {
        state.loading = false;
        state.documents = action.payload;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(uploadDocumentWithFile.fulfilled, (state, action: PayloadAction<Document>) => {
        // Ensure the new document has a placeholder for the file data
        const newDocument = {
          ...action.payload,
          attributes: {
            ...action.payload.attributes,
            file: action.payload.attributes.file || { data: null },
          },
        };
        state.documents.push(newDocument);
      })
      .addCase(refreshDocument.fulfilled, (state, action: PayloadAction<Document>) => {
        const index = state.documents.findIndex(doc => doc.id === action.payload.id);
        if (index !== -1) {
          state.documents[index] = action.payload;
        }
      })
      .addCase(deleteDocument.fulfilled, (state, action: PayloadAction<string>) => {
        state.documents = state.documents.filter(doc => doc.id !== action.payload);
      });
  },
});

export default documentSlice.reducer;

export const selectAllDocuments = (state: RootState) => state.documents.documents;
export const selectDocumentsLoading = (state: RootState) => state.documents.loading;
export const selectDocumentsError = (state: RootState) => state.documents.error;
