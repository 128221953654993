import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Upload, File } from 'lucide-react';

interface DocumentUploadProps {
  onUpload: (file: File, type: string, title: string) => void;
}

const DocumentUploadSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  type: Yup.string().required('Document type is required'),
  file: Yup.mixed().required('File is required'),
});

const DocumentUpload: React.FC<DocumentUploadProps> = ({ onUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  return (
    <Formik
      initialValues={{ title: '', type: 'report', file: null }}
      validationSchema={DocumentUploadSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (selectedFile) {
          onUpload(selectedFile, values.type, values.title);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className="space-y-4">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Document Title
            </label>
            <Field
              name="title"
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <ErrorMessage name="title" component="div" className="text-red-500 text-xs mt-1" />
          </div>

          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700">
              Document Type
            </label>
            <Field
              name="type"
              as="select"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="report">Report</option>
              <option value="image">Image</option>
              <option value="lab_result">Lab Result</option>
              <option value="other">Other</option>
            </Field>
            <ErrorMessage name="type" component="div" className="text-red-500 text-xs mt-1" />
          </div>

          <div>
            <label htmlFor="file" className="block text-sm font-medium text-gray-700">
              File
            </label>
            <input
              id="file"
              name="file"
              type="file"
              onChange={(event) => {
                handleFileChange(event);
                setFieldValue("file", event.currentTarget.files?.[0]);
              }}
              className="mt-1 block w-full"
            />
            <ErrorMessage name="file" component="div" className="text-red-500 text-xs mt-1" />
          </div>

          {selectedFile && (
            <div className="flex items-center space-x-2 text-sm text-gray-500">
              <File size={16} />
              <span>{selectedFile.name}</span>
            </div>
          )}

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 flex items-center justify-center"
          >
            <Upload size={16} className="mr-2" />
            {isSubmitting ? 'Uploading...' : 'Upload Document'}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default DocumentUpload;