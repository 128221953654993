import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { motion } from "framer-motion";
import { ArrowLeft, Save, User } from "lucide-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import { createPatient, updatePatient, fetchPatient } from "../../store/slices/patientSlice";

const PatientSchema = Yup.object().shape({
  externalId: Yup.string().required("External ID is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  dateOfBirth: Yup.date().required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  contactInfo: Yup.string().required("Contact information is required"),
});

const PatientForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const { currentPatient, loading, error } = useSelector((state: RootState) => state.patients);

  useEffect(() => {
    if (id) {
      dispatch(fetchPatient(id));
    }
  }, [id, dispatch]);

  const initialValues = id && currentPatient
    ? {
        externalId: currentPatient.attributes.externalId,
        firstName: currentPatient.attributes.firstName,
        lastName: currentPatient.attributes.lastName,
        dateOfBirth: currentPatient.attributes.dateOfBirth,
        gender: currentPatient.attributes.gender,
        contactInfo: currentPatient.attributes.contactInfo,
      }
    : {
        externalId: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        contactInfo: "",
      };

  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    try {
      if (id) {
        await dispatch(updatePatient({ id, data: values })).unwrap();
      } else {
        await dispatch(createPatient(values)).unwrap();
      }
      navigate("/patients");
    } catch (error) {
      console.error("Failed to save patient:", error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-4 text-red-500">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <Link
          to="/patients"
          className="text-blue-500 hover:underline flex items-center"
        >
          <ArrowLeft size={20} className="mr-2" />
          Back to Patients List
        </Link>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 bg-gray-50 border-b">
          <h2 className="text-2xl font-bold text-gray-800">
            {id ? "Edit Patient" : "Add New Patient"}
          </h2>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={PatientSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="externalId"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    External ID
                  </label>
                  <Field
                    name="externalId"
                    type="text"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="externalId"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    First Name
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Last Name
                  </label>
                  <Field
                    type="text"
                    name="lastName"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="dateOfBirth"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Date of Birth
                  </label>
                  <Field
                    type="date"
                    name="dateOfBirth"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="dateOfBirth"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Gender
                  </label>
                  <Field
                    as="select"
                    name="gender"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="contactInfo"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Contact Information
                  </label>
                  <Field
                    type="text"
                    name="contactInfo"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="contactInfo"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>
             

              <div className="flex justify-end space-x-4 mt-8">              
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={() => navigate("/patients")}
                  className="bg-white-500 text-black px-6 py-2 rounded-lg flex items-center shadow-md hover:bg-blue-600 transition-colors duration-200"
                >
                Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg flex items-center shadow-md hover:bg-blue-600 transition-colors duration-200"
                >
                  <Save className="mr-2" size={20} />
                  {isSubmitting ? "Saving..." : "Save Patient"}
                </motion.button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PatientForm;