import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { Meeting } from '../../services/meetingService';
import { Task } from '../../services/taskService';
import { TreatmentPlan } from '../../services/treatmentPlanService';

// Type guard functions
const isMeeting = (item: Meeting | Task | TreatmentPlan): item is Meeting => {
  return 'attributes' in item && 'dateTime' in item.attributes;
};

const isTreatmentPlan = (item: Meeting | Task | TreatmentPlan): item is TreatmentPlan => {
  return 'attributes' in item && 'status' in item.attributes && !('dateTime' in item.attributes);
};

const isTask = (item: Meeting | Task | TreatmentPlan): item is Task => {
  return !('attributes' in item);
};

const RecentActivity: React.FC<{ items: (Meeting | Task | TreatmentPlan)[] }> = ({ items }) => {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold mb-4">Recent Activity</h2>
      <div className="space-y-4">
        {items
          .sort((a, b) => {
            const dateA = isTask(a) ? new Date(a.dueDate) : new Date(a.attributes.createdAt?a.attributes.createdAt:"" );
            const dateB = isTask(b) ? new Date(b.dueDate) : new Date(b.attributes.createdAt? b.attributes.createdAt:"");
            return dateB.getTime() - dateA.getTime();
          })
          .slice(0, 5)
          .map((item, index) => (
            <div key={index} className="flex items-center justify-between border-b pb-2">
              <div>
                <p className="font-semibold">
                  {isTask(item) ? item.title :
                   isMeeting(item) ? `Meeting: ${item.attributes.title}` :
                   `Treatment Plan: ${item.attributes.title}`}
                </p>
                <p className="text-sm text-gray-500">
                  {isTask(item) ? new Date(item.dueDate).toLocaleString() :
                   new Date(item.attributes.createdAt? item.attributes.createdAt:"").toLocaleString()}
                </p>
              </div>
              <Link to={
                isTask(item) ? `/tasks/${item.id}` :
                isMeeting(item) ? `/meetings/${item.id}` :
                `/treatment-plans/${item.id}`
              } className="text-blue-500 hover:text-blue-700">
                <ArrowRight size={20} />
              </Link>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default RecentActivity;
