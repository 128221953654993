import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import patientReducer from './slices/patientSlice';
import treatmentPlanReducer from './slices/treatmentPlanSlice';
import meetingReducer from './slices/meetingSlice';
import taskReducer from './slices/taskSlice';
import hl7Reducer from './slices/hl7Slice';
import userReducer from './slices/userSlice';
import medicalRecordReducer from './slices/medicalRecordSlice';
import doctorSpecialtyReducer from './slices/doctorSpecialtySlice';
import documentReducer from './slices/documentSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    patients: patientReducer,
    treatmentPlans: treatmentPlanReducer,
    meetings: meetingReducer,
    tasks: taskReducer,
    hl7: hl7Reducer,
    users: userReducer,
    medicalRecord: medicalRecordReducer,
    doctorSpecialty: doctorSpecialtyReducer,
    documents: documentReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;