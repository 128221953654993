// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { RootState } from './store';
// import MainLayout from './components/MainLayout';
// import Dashboard from './components/Dashboard';
// import PatientList from './components/Patient/PatientList';
// import PatientForm from './components/Patient/PatientForm';
// import TreatmentPlanList from './components/TreatmentPlan/TreatmentPlanList';
// import TreatmentPlanDetail from './components/TreatmentPlan/TreatmentPlanDetail';
// import TreatmentPlanForm from './components/TreatmentPlan/TreatmentPlanForm';
// import MeetingList from './components/Meeting/MeetingList';
// import MeetingDetail from './components/Meeting/MeetingDetail';
// import MeetingForm from './components/Meeting/MeetingForm';
// import TaskList from './components/Task/TaskList';
// import TaskDetail from './components/Task/TaskDetail';
// import TaskForm from './components/Task/TaskForm';
// import HL7Processor from './components/HL7/HL7Processor';
// import Login from './components/Auth/Login';
// import PatientProfile from './components/Patient/PatientProfile';
// import MeetingsCalendar from './components/Meeting/MeetingsCalendar';
// import MedicalImagingViewer from './components/MedicalImagingViewer/MedicalImagingViewer';
// import PatientMedicalRecord from './components/Patient/PatientMedicalRecord';
// import PatientDashboard from './components/Patient/PatientDashboard';

// const App: React.FC = () => {
//   const { user } = useSelector((state: RootState) => state.auth);

//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
//         <Route
//           path="/"
//           element={user ? <MainLayout /> : <Navigate to="/login" />}
//         >
//           <Route index element={<Dashboard />} />
//           <Route path="patients">
//             <Route index element={<PatientList />} />
//             <Route path="new" element={<PatientForm />} />
//             <Route path=":id" element={<PatientMedicalRecord />} />
//             <Route path=":id/edit" element={<PatientForm />} />
//           </Route>
//           <Route path="treatment-plans">
//             <Route index element={<TreatmentPlanList />} />
//             <Route path="new" element={<TreatmentPlanForm />} />
//             <Route path=":id" element={<TreatmentPlanDetail />} />
//             <Route path=":id/edit" element={<TreatmentPlanForm />} />
//           </Route>
//           <Route path="meetings">
//             <Route index element={<MeetingsCalendar />} />
//             <Route path="new" element={<MeetingForm />} />
//             <Route path=":id" element={<MeetingDetail />} />
//             <Route path=":id/edit" element={<MeetingForm />} />
//           </Route>
//           <Route path="tasks">
//             <Route index element={<TaskList />} />
//             <Route path="new" element={<TaskForm />} />
//             <Route path=":id" element={<TaskDetail />} />
//             <Route path=":id/edit" element={<TaskForm />} />
//           </Route>
//           <Route path="hl7" element={<HL7Processor />} />
//           <Route path="/imaging-viewer" element={<MedicalImagingViewer />} />

//         </Route>
//       </Routes>
//     </Router>
//   );
// };

// export default App;
// src/App.tsx
// src/App.tsx

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store";
import { fetchCurrentUser } from "./store/slices/authSlice";
import MainLayout from "./components/MainLayout";
import Login from "./components/Auth/Login";
import PatientList from "./components/Patient/PatientList";
import PatientForm from "./components/Patient/PatientForm";
import PatientMedicalRecord from "./components/Patient/PatientMedicalRecord";
import TreatmentPlanList from "./components/TreatmentPlan/TreatmentPlanList";
import TreatmentPlanForm from "./components/TreatmentPlan/TreatmentPlanForm";
import TreatmentPlanDetail from "./components/TreatmentPlan/TreatmentPlanDetail";
// import MedicalImagingViewer from './components/MedicalImagingViewer/MedicalImagingViewer';

import LandingPage from "./components/LandingPage/LandingPage";
import MeetingDetail from "./components/Meeting/MeetingDetail";
import MeetingForm from "./components/Meeting/MeetingForm";
import TaskList from "./components/Task/TaskList";
import TaskDetail from "./components/Task/TaskDetail";
import TaskForm from "./components/Task/TaskForm";
import HL7Processor from "./components/HL7/HL7Processor";

import Dashboard from "./components/Dashboard";
import { store } from "./store";
import { Provider } from "react-redux";
import MeetingsCalendar from "./components/Meeting/MeetingsCalendar";
import UserAndSpecialtyManagement from "./components/UserAndSpecialty/UserAndSpecialtyManagement";
import UsersList from "./components/User/UsersList";
import UserForm from "./components/User/UserForm";
import UserDetail from "./components/User/UserDetail";

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user, loading } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Provider store={store}>
      <Router>
        <Routes>
        <Route path="/landing" element={<LandingPage />} />
          <Route
            path="/login"
            element={user ? <Navigate to="/" replace /> : <Login />}
          />

          <Route
            path="/*"
            element={
              user ? (
                <MainLayout>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/patients" element={<PatientList />} />
                    <Route path="/patients/new" element={<PatientForm />} />
                    <Route
                      path="/patients/:id"
                      element={<PatientMedicalRecord />}
                    />
                    <Route
                      path="/patients/:id/edit"
                      element={<PatientForm />}
                    />
                    <Route
                      path="/treatment-plans"
                      element={<TreatmentPlanList />}
                    />
                    <Route
                      path="/treatment-plans/new"
                      element={<TreatmentPlanForm />}
                    />
                    <Route
                      path="/treatment-plans/:id"
                      element={<TreatmentPlanDetail />}
                    />
                    <Route
                      path="/treatment-plans/:id/edit"
                      element={<TreatmentPlanForm />}
                    />
                    <Route path="meetings">
                      <Route index element={<MeetingsCalendar />} />
                      <Route path="new" element={<MeetingForm />} />
                      <Route path=":id" element={<MeetingDetail />} />
                      <Route path=":id/edit" element={<MeetingForm />} />
                    </Route>
                    <Route path="tasks">
                      <Route index element={<TaskList />} />
                      <Route path="new" element={<TaskForm />} />
                      <Route path=":id" element={<TaskDetail />} />
                      <Route path=":id/edit" element={<TaskForm />} />
                    </Route>
                    {/* <Route path="hl7" element={<HL7Processor />} /> */}
                    {/* <Route path="/imaging-viewer" element={<MedicalImagingViewer />} /> */}
                    <Route path="/manage-users" element={<UserAndSpecialtyManagement />} />
                    <Route path="/users" element={<UsersList />} />
                    <Route path="/users/new" element={<UserForm />} />
                    <Route path="/users/:id" element={<UserDetail />} />
                    <Route path="/users/:id/edit" element={<UserForm />} />

                  </Routes>
                </MainLayout>
              ) : (
                <Navigate to="/landing" replace />
              )
            }
          />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
