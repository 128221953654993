import api from "./api";
import { Meeting } from "./meetingService";
import { Task } from "./taskService";
import { TreatmentPlan } from "./treatmentPlanService";

export interface MedicalHistoryItem {
  id: string;
  date: string;
  description: string;
  doctor: string;
}

export interface VitalSign {
  id: string;
  date: string;
  heartRate: number;
  bloodPressure: string;
  temperature: number;
}

export interface MedicalEvent {
  id: string;
  date: string;
  type: "Appointment" | "Diagnosis" | "Procedure" | "Medication";
  description: string;
}

export interface Document {
    id: string;
    attributes: {
      title: string;
      file: {
        data: {
          attributes: {
            url: string;
          }
        }
      };
      type: 'report' | 'image' | 'lab_result' | 'other';
      patient: {
        data: {
          id: string;
          attributes: {
            firstName: string;
            lastName: string;
          }
        }
      };
      uploader: {
        data: {
          id: string;
          attributes: {
            username: string;
          }
        }
      };
      version: number;
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
    };
  }
  

const medicalRecordService = {
  getMedicalHistory: async (
    patientId: string
  ): Promise<MedicalHistoryItem[]> => {
    const response = await api.get(
      `/api/medical-histories?filters[patient][id][$eq]=${patientId}&sort=date:desc`
    );
    return response.data.data.map((item: any) => ({
      id: item.id,
      ...item.attributes,
    }));
  },

  addMedicalHistoryItem: async (
    patientId: string,
    item: Omit<MedicalHistoryItem, "id">
  ): Promise<MedicalHistoryItem> => {
    const response = await api.post("/api/medical-histories", {
      data: { ...item, patient: patientId },
    });
    return {
      id: response.data.data.id,
      ...response.data.data.attributes,
    };
  },

  getVitalSigns: async (patientId: string): Promise<VitalSign[]> => {
    const response = await api.get(
      `/api/vital-signs?filters[patient][id][$eq]=${patientId}&sort=date:desc`
    );
    return response.data.data.map((item: any) => ({
      id: item.id,
      ...item.attributes,
    }));
  },

  addVitalSign: async (
    patientId: string,
    vitalSign: Omit<VitalSign, "id">
  ): Promise<VitalSign> => {
    const response = await api.post("/api/vital-signs", {
      data: { ...vitalSign, patient: patientId },
    });
    return {
      id: response.data.data.id,
      ...response.data.data.attributes,
    };
  },

  getMedicalEvents: async (patientId: string): Promise<MedicalEvent[]> => {
    const response = await api.get(
      `/api/medical-events?filters[patient][id][$eq]=${patientId}&sort=date:desc`
    );
    return response.data.data.map((item: any) => ({
      id: item.id,
      ...item.attributes,
    }));
  },

  addMedicalEvent: async (
    patientId: string,
    event: Omit<MedicalEvent, "id">
  ): Promise<MedicalEvent> => {
    const response = await api.post("/api/medical-events", {
      data: { ...event, patient: patientId },
    });
    return {
      id: response.data.data.id,
      ...response.data.data.attributes,
    };
  },
  getTreatmentPlans: async (patientId: string): Promise<TreatmentPlan[]> => {
    const response = await api.get(
      `/api/treatment-plans?filters[patient][id][$eq]=${patientId}&sort=startDate:desc&populate=*`
    )
    return response.data;
    // return response.data.data.map((item: any) => ({
    //   id: item.id,
    //   ...item.attributes,
    //   patient: {
    //     id: item.attributes.patient.data.id,
    //     name: `${item.attributes.patient.data.attributes.firstName} ${item.attributes.patient.data.attributes.lastName}`,
    //   },
    //   collaborators: item.attributes.collaborators.data.map(
    //     (collaborator: any) => ({
    //       id: collaborator.id,
    //       username: collaborator.attributes.username,
    //     })
    //   ),
    //   tasks: item.attributes.tasks.data.map((task: any) => ({
    //     id: task.id,
    //     ...task.attributes,
    //   })),
    // }));
  },

  getMeetings: async (patientId: string): Promise<Meeting[]> => {
    const response = await api.get(
      `/api/meetings?filters[patients][id][$eq]=${patientId}&sort=dateTime:desc&populate=*`
    );
    return response.data.data.map((item: any) => ({
      id: item.id,
      ...item.attributes,
      attendees: item.attributes.attendees.data.map((attendee: any) => ({
        id: attendee.id,
        ...attendee.attributes,
      })),
      patients: item.attributes.patients.data.map((patient: any) => ({
        id: patient.id,
        ...patient.attributes,
      })),
      tasks: item.attributes.tasks.data.map((task: any) => ({
        id: task.id,
        ...task.attributes,
      })),
    }));
  },

  getTasks: async (patientId: string): Promise<Task[]> => {
    const response = await api.get(
      `/api/tasks?filters[treatmentPlan][patient][id][$eq]=${patientId}&sort=dueDate:asc&populate=*`
    );
    return response.data.data.map((item: any) => ({
      id: item.id,
      ...item.attributes,
      assignee: item.attributes.assignee?.data
        ? {
            id: item.attributes.assignee.data.id,
            ...item.attributes.assignee.data.attributes,
          }
        : undefined,
      treatmentPlan: item.attributes.treatmentPlan?.data
        ? {
            id: item.attributes.treatmentPlan.data.id,
            ...item.attributes.treatmentPlan.data.attributes,
          }
        : undefined,
      meeting: item.attributes.meeting?.data
        ? {
            id: item.attributes.meeting.data.id,
            ...item.attributes.meeting.data.attributes,
          }
        : undefined,
    }));
  },

  getDocuments: async (patientId: string): Promise<Document[]> => {
    const response = await api.get(
      `/api/documents?filters[patient][id][$eq]=${patientId}&sort=createdAt:desc`
    );
    return response.data.data.map((item: any) => ({
      id: item.id,
      ...item.attributes,
    }));
  },
};

export default medicalRecordService;
