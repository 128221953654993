import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  ArrowLeft,
  Edit,
  Trash2,
  Video,
  MapPin,
  Clock,
  Users,
  Calendar,
  FileText,
  AlertCircle,
} from "lucide-react";
import {
  fetchMeeting,
  deleteMeeting,
  selectCurrentMeeting,
  selectMeetingsLoading,
  selectMeetingsError,
} from "../../store/slices/meetingSlice";
import { AppDispatch, RootState } from "../../store";

const MeetingDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const meeting = useSelector((state: RootState) =>
    selectCurrentMeeting(state)
  );
  const loading = useSelector((state: RootState) =>
    selectMeetingsLoading(state)
  );
  const error = useSelector((state: RootState) => selectMeetingsError(state));

  useEffect(() => {
    if (id) {
      dispatch(fetchMeeting(id));
    }
  }, [dispatch, id]);

  const handleDelete = async () => {
    if (id && window.confirm("Are you sure you want to delete this meeting?")) {
      try {
        await dispatch(deleteMeeting(id)).unwrap();
        navigate("/meetings");
      } catch (error) {
        console.error("Failed to delete meeting:", error);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <Clock size={48} className="text-blue-500" />
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-red-500">
        <AlertCircle size={48} className="mb-4" />
        <p className="text-xl font-semibold">{error}</p>
      </div>
    );
  }

  if (!meeting || !meeting.attributes) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-gray-500">
        <FileText size={48} className="mb-4" />
        <p className="text-xl font-semibold">Meeting not found</p>
      </div>
    );
  }

  const { attributes } = meeting;

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-6">
          <Link
            to="/meetings"
            className="text-blue-500 hover:underline flex items-center"
          >
            <ArrowLeft size={20} className="mr-2" />
            Back to Meetings Calendar
          </Link>
        </div>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-6 bg-gradient-to-r from-blue-500 to-purple-600 text-white">
            <div className="flex justify-between items-center">
              <h1 className="text-3xl font-bold">{attributes.title}</h1>
              <div className="flex space-x-4">
                <Link to={`/meetings/${id}/edit`}>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-yellow-500 text-white px-4 py-2 rounded-lg flex items-center"
                  >
                    <Edit className="mr-2" size={20} />
                    Edit Meeting
                  </motion.button>
                </Link>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-red-500 text-white px-4 py-2 rounded-lg flex items-center"
                  onClick={handleDelete}
                >
                  <Trash2 className="mr-2" size={20} />
                  Delete Meeting
                </motion.button>
              </div>
            </div>
          </div>

          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h2 className="text-xl font-semibold mb-4">Meeting Details</h2>
                <div className="space-y-2">
                  <p className="flex items-center">
                    <Calendar className="mr-2 text-blue-500" size={20} />
                    {new Date(attributes.dateTime).toLocaleString()}
                  </p>
                  <p className="flex items-center">
                    <Clock className="mr-2 text-blue-500" size={20} />
                    Duration: {attributes.duration} minutes
                  </p>
                  <p className="flex items-center">
                    {attributes.isVirtual ? (
                      <>
                        <Video className="mr-2 text-green-500" size={20} />
                        Virtual Meeting
                      </>
                    ) : (
                      <>
                        <MapPin className="mr-2 text-red-500" size={20} />
                        In-person Meeting
                      </>
                    )}
                  </p>
                  {attributes.isVirtual && attributes.videoConferenceLink && (
                    <p className="flex items-center">
                      <a
                        href={attributes.videoConferenceLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        Join Video Conference
                      </a>
                    </p>
                  )}
                  {!attributes.isVirtual && attributes.location && (
                    <p className="flex items-center">
                      <MapPin className="mr-2 text-red-500" size={20} />
                      {attributes.location}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-4">Attendees</h2>
                <ul className="space-y-2">
                  {attributes.attendees.data.map((attendee) => (
                    <li
                      key={attendee.id}
                      className="flex items-center bg-gray-100 p-2 rounded"
                    >
                      <Users className="mr-2 text-blue-500" size={20} />
                      <span>{`${attendee.attributes.firstName} ${attendee.attributes.lastName}`}</span>
                    </li>
                  ))}
                  {attributes.attendees.data.length === 0 && (
                    <li>No attendees</li>
                  )}
                </ul>
              </div>
            </div>

            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Patients</h2>
              <ul className="space-y-2">
                {attributes.patients.data.map((patient) => (
                  <li
                    key={patient.id}
                    className="flex items-center bg-gray-100 p-2 rounded"
                  >
                    <Users className="mr-2 text-green-500" size={20} />
                    <span>{`${patient.attributes.firstName} ${patient.attributes.lastName}`}</span>
                  </li>
                ))}
                {attributes.patients.data.length === 0 && <li>No patients</li>}
              </ul>
            </div>

            <AnimatePresence>
              {attributes.notes && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  className="mt-8"
                >
                  <h2 className="text-xl font-semibold mb-4">Notes</h2>
                  <div className="bg-gray-100 p-4 rounded-lg">
                    <FileText className="inline mr-2 text-blue-500" size={20} />
                    <span>{attributes.notes}</span>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {attributes.tasks?.data?.length > 0 && (
              <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Related Tasks</h2>
                <ul className="space-y-2">
                  {attributes.tasks.data.map((task) => (
                    <li
                      key={task.id}
                      className="flex items-center justify-between bg-gray-100 p-2 rounded"
                    >
                      <span>{task.attributes.title}</span>
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${
                          task.attributes.status === "completed"
                            ? "bg-green-200 text-green-800"
                            : task.attributes.status === "in_progress"
                            ? "bg-yellow-200 text-yellow-800"
                            : "bg-red-200 text-red-800"
                        }`}
                      >
                        {task.attributes.status}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default MeetingDetail;
