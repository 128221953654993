import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store'; // Make sure this path is correct
import App from './App';
import './index.css'; // Add this line
import './App.css'; // And
import './components/ui/ui-components.css'
const container = document.getElementById('root');
const root = createRoot(container!); // The '!' is used here to assert that container is not null
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);