import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { motion, AnimatePresence } from "framer-motion";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Chip,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Autocomplete,
  CircularProgress,
  Snackbar,
  Alert,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { ArrowBack, ArrowForward, Save } from "@mui/icons-material";

import { AppDispatch, RootState } from "../../store";
import {
  fetchPatients,
  selectAllPatients,
} from "../../store/slices/patientSlice";
import { fetchUsers, selectAllUsers } from "../../store/slices/userSlice";
import {
  fetchSpecialties,
  selectAllSpecialties,
} from "../../store/slices/doctorSpecialtySlice";
import api from "../../services/api";
import { Patient } from "../../store/slices/patientSlice";
import { User } from "../../store/slices/userSlice";
import { DoctorSpecialty } from "../../store/slices/doctorSpecialtySlice";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(3, 0),
  backgroundColor: theme.palette.background.default,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

// interface TreatmentPlanFormData {
//   title: string;
//   description: string;
//   startDate: Date;
//   endDate: Date | null;
//   status: "planned" | "in_progress" | "completed" | "cancelled";
//   patient: string;
//   collaborators: string[];
//   specialties: string[];
// }

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  startDate: yup.date().required("Start date is required"),
  endDate: yup
    .date()
    .nullable()
    .min(yup.ref("startDate"), "End date must be after start date"),
  status: yup
    .string()
    .oneOf(["planned", "in_progress", "completed", "cancelled"])
    .required("Status is required"),
  patient: yup.string().required("Patient is required"),
  collaborators: yup.array().of(yup.number()).defined(),
  specialties: yup.array().of(yup.string()).defined(),
});
type TreatmentPlanFormData = yup.InferType<typeof schema>;

const steps = [
  "Basic Info",
  "Dates & Status",
  "Patient & Collaborators",
  "Review",
];

const TreatmentPlanForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isEditMode = !!id;
  const [collaboratorSpecialtyFilter, setCollaboratorSpecialtyFilter] =
    useState<string[]>([]);

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const patients = useSelector(selectAllPatients);
  const users = useSelector(selectAllUsers);
  const specialties = useSelector(selectAllSpecialties);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    reset,
  } = useForm<TreatmentPlanFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      description: "",
      startDate: new Date(),
      endDate: null,
      status: "planned",
      patient: "",
      collaborators: [],
      specialties: [],
    },
  });

  const watchedSpecialties = watch("specialties");

  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchUsers());
    dispatch(fetchSpecialties());

    if (isEditMode && id) {
      fetchTreatmentPlan(id);
    }
  }, [dispatch, isEditMode, id]);

  const fetchTreatmentPlan = async (planId: string) => {
    try {
      setLoading(true);
      const response = await api.get(
        `/api/treatment-plans/${planId}?populate=*`
      );
      const planData = response.data.data;

      reset({
        title: planData.attributes.title,
        description: planData.attributes.description,
        startDate: new Date(planData.attributes.startDate),
        endDate: planData.attributes.endDate
          ? new Date(planData.attributes.endDate)
          : null,
        status: planData.attributes.status,
        patient: planData.attributes.patient.data.id,
        collaborators: planData.attributes.collaborators.data.map(
          (c: any) => c.id
        ),
        specialties: [], // Set to empty array as it's not present in the response
      });
    } catch (error) {
      console.error("Error fetching treatment plan:", error);
      setSnackbar({
        open: true,
        message: "Failed to fetch treatment plan data",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: TreatmentPlanFormData) => {
    setLoading(true);
    try {
      const formattedData = {
        data: {
          title: data.title,
          description: data.description,
          startDate: data.startDate.toISOString(),
          endDate: data.endDate ? data.endDate.toISOString() : null,
          status: data.status,
          patient: data.patient,
          collaborators: data.collaborators,
          ...(data.specialties.length > 0 && { specialties: data.specialties }),
        },
      };

      if (isEditMode && id) {
        await api.put(`/api/treatment-plans/${id}`, formattedData);
        setSnackbar({
          open: true,
          message: "Treatment plan updated successfully",
          severity: "success",
        });
      } else {
        await api.post("/api/treatment-plans", formattedData);
        setSnackbar({
          open: true,
          message: "Treatment plan created successfully",
          severity: "success",
        });
      }
      navigate("/treatment-plans");
    } catch (error) {
      console.error("Error saving treatment plan:", error);
      setSnackbar({
        open: true,
        message: "Failed to save treatment plan",
        severity: "error",
      });
    }
    setLoading(false);
  };

  const handleNext = async () => {
    let fieldsToValidate: (keyof TreatmentPlanFormData)[] = [];

    switch (activeStep) {
      case 0:
        fieldsToValidate = ["title", "description"];
        break;
      case 1:
        fieldsToValidate = ["startDate", "endDate", "status"];
        break;
      case 2:
        fieldsToValidate = ["patient", "collaborators", "specialties"];
        break;
      default:
        break;
    }

    const isStepValid = await trigger(fieldsToValidate);

    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Optionally, you can show an error message here
      setSnackbar({
        open: true,
        message:
          "Please fill in all required fields correctly before proceeding.",
        severity: "error",
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const filterUsersBySpecialties = (user: User) => {
    if (watchedSpecialties.length === 0) return true;
    return user.specialties?.some((specialty) => {
      const specialtyId = specialty.id.toString();
      const selectedSpecialty = specialties.find(
        (s) => s.id.toString() === specialtyId
      );

      // Check if the specialty is directly selected
      if (watchedSpecialties.includes(specialtyId)) return true;

      // Check if the parent specialty is selected (for subspecialties)
      if (
        selectedSpecialty?.attributes.isSubSpecialty &&
        selectedSpecialty.attributes.parentSpecialty?.data?.id
      ) {
        return watchedSpecialties.includes(
          selectedSpecialty.attributes.parentSpecialty.data.id.toString()
        );
      }

      return false;
    });
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title"
                  variant="outlined"
                  fullWidth
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  margin="normal"
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  margin="normal"
                />
              )}
            />
          </>
        );
      case 1:
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Start Date"
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.startDate,
                        helperText: errors.startDate?.message,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="End Date"
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.endDate,
                        helperText: errors.endDate?.message,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Status"
                    variant="outlined"
                    fullWidth
                    error={!!errors.status}
                  >
                    <MenuItem value="planned">Planned</MenuItem>
                    <MenuItem value="in_progress">In Progress</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                    <MenuItem value="cancelled">Cancelled</MenuItem>
                  </Select>
                )}
              />
            </Box>
          </LocalizationProvider>
        );
      case 2:
        return (
          <>
            <Controller
              name="patient"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={patients}
                  getOptionLabel={(option: Patient) =>
                    `${option.attributes.firstName} ${option.attributes.lastName}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Patient"
                      variant="outlined"
                      error={!!errors.patient}
                      helperText={errors.patient?.message}
                      fullWidth
                      margin="normal"
                    />
                  )}
                  onChange={(_, newValue) =>
                    field.onChange(newValue ? newValue.id : "")
                  }
                  value={patients.find((p) => p.id === field.value) || null}
                />
              )}
            />
            {/* <Controller
  name="specialties"
  control={control}
  render={({ field }) => (
    <Autocomplete
      {...field}
      multiple
      options={specialties}
      getOptionLabel={(option: DoctorSpecialty) => option.attributes.name}
      groupBy={(option: DoctorSpecialty) => 
        option.attributes.isSubSpecialty ? 'Subspecialties' : 'Specialties'
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Specialties and Subspecialties"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      )}
      onChange={(_, newValue) =>
        field.onChange(newValue.map((v) => v.id.toString()))
      }
      value={specialties.filter((s) =>
        field.value.includes(s.id.toString())
      )}
    />
  )}
/> */}

            {/* <Controller
              name="collaborators"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={users.filter(filterUsersBySpecialties)}
                  getOptionLabel={(option: User) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Collaborators"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  )}
                  onChange={(_, newValue) =>
                    field.onChange(newValue.map((v) => v.id))
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div>
                        {`${option.firstName} ${option.lastName}`}
                        <div style={{ fontSize: "0.8em", color: "gray" }}>
                          Specialties:{" "}
                          {option.specialties?.map((s) => s.name).join(", ")}
                        </div>
                      </div>
                    </li>
                  )}
                /> */}
            <Autocomplete
              multiple
              options={specialties}
              getOptionLabel={(option: DoctorSpecialty) =>
                option.attributes.name
              }
              groupBy={(option: DoctorSpecialty) =>
                option.attributes.isSubSpecialty
                  ? "Subspecialties"
                  : "Specialties"
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter Collaborators by Specialty"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
              onChange={(_, newValue) =>
                setCollaboratorSpecialtyFilter(
                  newValue.map((v) => v.id.toString())
                )
              }
            />
            <Controller
              name="collaborators"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={users.filter(
                    (user) =>
                      collaboratorSpecialtyFilter.length === 0 ||
                      user.specialties?.some((specialty) =>
                        collaboratorSpecialtyFilter.includes(
                          specialty.id.toString()
                        )
                      )
                  )}
                  getOptionLabel={(option: User) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Collaborators"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  )}
                  onChange={(_, newValue) =>
                    field.onChange(newValue.map((v) => v.id))
                  }
                  value={users.filter((u) => field.value.includes(u.id))}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <div>
                        {`${option.firstName} ${option.lastName}`}
                        <div style={{ fontSize: "0.8em", color: "gray" }}>
                          Specialties:{" "}
                          {option.specialties?.map((s) => s.name).join(", ")}
                        </div>
                      </div>
                    </li>
                  )}
                />
              )}
            />
          </>
        );
      case 3:
        const watchedValues = watch();
        const selectedCollaborators = users.filter((u) =>
          watchedValues.collaborators.includes(u.id)
        );
        const selectedPatient = patients.find(
          (p) => p.id === watchedValues.patient
        );
        const selectedSpecialties = specialties.filter((s) =>
          watchedValues.specialties.includes(s.id.toString())
        );

        return (
          <Box>
            <Typography variant="h5" gutterBottom color="primary">
              Treatment Plan Summary
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: 3, height: "100%" }}>
                  <Typography variant="h6" gutterBottom>
                    Basic Information
                  </Typography>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                      label="Title"
                      value={watchedValues.title}
                      fullWidth
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                    <TextField
                      label="Description"
                      value={watchedValues.description}
                      fullWidth
                      multiline
                      rows={3}
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: 3, height: "100%" }}>
                  <Typography variant="h6" gutterBottom>
                    Dates & Status
                  </Typography>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                      label="Start Date"
                      value={watchedValues.startDate?.toLocaleDateString()}
                      fullWidth
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                    <TextField
                      label="End Date"
                      value={
                        watchedValues.endDate?.toLocaleDateString() || "Not set"
                      }
                      fullWidth
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                    <TextField
                      label="Status"
                      value={watchedValues.status}
                      fullWidth
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Patient & Collaborators
                  </Typography>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                      label="Patient"
                      value={
                        selectedPatient
                          ? `${selectedPatient.attributes.firstName} ${selectedPatient.attributes.lastName}`
                          : "Not selected"
                      }
                      fullWidth
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                    />
                    <Typography variant="subtitle1" gutterBottom>
                      Collaborators and Their Specialties:
                    </Typography>
                    <List>
                      {selectedCollaborators.map((collaborator) => (
                        <ListItem key={collaborator.id} disablePadding>
                          <ListItemAvatar>
                            <Avatar>
                              {collaborator.firstName[0]}
                              {collaborator.lastName[0]}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${collaborator.firstName} ${collaborator.lastName}`}
                            secondary={
                              <>
                                {collaborator.specialties?.map((specialty) => (
                                  <Chip
                                    key={specialty.id}
                                    label={specialty.name}
                                    size="small"
                                    sx={{ mr: 0.5, mb: 0.5 }}
                                  />
                                ))}
                              </>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom>
          Create Treatment Plan
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeStep}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
            >
              <Box my={4}>{renderStepContent(activeStep)}</Box>
            </motion.div>
          </AnimatePresence>
          <Box mt={3} display="flex" justifyContent="space-between">
            <StyledButton
              disabled={activeStep === 0}
              onClick={handleBack}
              startIcon={<ArrowBack />}
            >
              Back
            </StyledButton>
            <Box>
              {activeStep !== steps.length - 1 && (
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  endIcon={<ArrowForward />}
                >
                  Next
                </StyledButton>
              )}
              {activeStep === steps.length - 1 && (
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={
                    loading ? <CircularProgress size={20} /> : <Save />
                  }
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save Treatment Plan"}
                </StyledButton>
              )}
            </Box>
          </Box>
        </form>
      </StyledPaper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </motion.div>
  );
};

export default TreatmentPlanForm;
