import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import taskService, {  TaskData } from '../../services/taskService';
import { Task } from '../../services/taskService';

interface TaskState {
  tasks: Task[];
  currentTask: Task | null;
  loading: boolean;
  error: string | null;
}

const initialState: TaskState = {
  tasks: [],
  currentTask: null,
  loading: false,
  error: null,
};

export const fetchTasks = createAsyncThunk(
  '/api/tasks/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      return await taskService.getAll();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchTask = createAsyncThunk(
  '/api/tasks/fetchOne',
  async (id: string, { rejectWithValue }) => {
    try {
      return await taskService.getById(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createTask = createAsyncThunk(
  '/api/tasks/create',
  async (data: TaskData, { rejectWithValue }) => {
    try {
      return await taskService.create(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateTask = createAsyncThunk(
  '/api/tasks/update',
  async ({ id, data }: { id: string; data: Partial<TaskData> }, { rejectWithValue }) => {
    try {
      return await taskService.update(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  '/api/tasks/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await taskService.delete(id);
      return id;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTasks.fulfilled, (state, action: PayloadAction<Task[]>) => {
        state.loading = false;
        state.tasks = action.payload;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTask.fulfilled, (state, action: PayloadAction<Task>) => {
        state.loading = false;
        state.currentTask = action.payload;
      })
      .addCase(fetchTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createTask.fulfilled, (state, action: PayloadAction<Task>) => {
        state.tasks.push(action.payload);
      })
      .addCase(updateTask.fulfilled, (state, action: PayloadAction<Task>) => {
        const index = state.tasks.findIndex((task) => task.id === action.payload.id);
        if (index !== -1) {
          state.tasks[index] = action.payload;
        }
        if (state.currentTask?.id === action.payload.id) {
          state.currentTask = action.payload;
        }
      })
      .addCase(deleteTask.fulfilled, (state, action: PayloadAction<string>) => {
        state.tasks = state.tasks.filter((task) => task.id !== action.payload);
        if (state.currentTask?.id === action.payload) {
          state.currentTask = null;
        }
      });
  },
});

export default taskSlice.reducer;

export const selectAllTasks = (state: { tasks: TaskState }) => state.tasks.tasks;
export const selectTaskById = (state: { tasks: TaskState }, id: string) =>
  state.tasks.tasks.find((task) => task.id === id);
export const selectCurrentTask = (state: { tasks: TaskState }) => state.tasks.currentTask;
export const selectTasksLoading = (state: { tasks: TaskState }) => state.tasks.loading;
export const selectTasksError = (state: { tasks: TaskState }) => state.tasks.error;