import React, { useState, useEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BsArrowRight, BsCheck2Circle } from "react-icons/bs";
import {
  FaHospital,
  FaUserMd,
  FaChartLine,
  FaLock,
  FaClock,
  FaMobileAlt,
} from "react-icons/fa";
import Lottie from "react-lottie-player";
import heartbeatAnimation from "./heartbeat-animation.json";
import { Link, useNavigate } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const LandingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { scrollYProgress } = useViewportScroll(); // Get scroll progress from useViewportScroll
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.5]); // Use scroll progress to transform scale
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoaded(true);

    gsap.to(".feature-card", {
      scrollTrigger: {
        trigger: ".features-section",
        start: "top center",
        end: "bottom center",
        scrub: 1,
      },
      y: 0,
      opacity: 1,
      stagger: 0.2,
    });
  }, []);

  const features = [
    {
      icon: <FaHospital />,
      title: "Integrated Care Coordination",
      description:
        "Seamlessly connect all departments for holistic patient care",
    },
    {
      icon: <FaUserMd />,
      title: "Multi-Disciplinary Collaboration",
      description:
        "Foster teamwork among specialists for comprehensive treatment plans",
    },
    {
      icon: <FaChartLine />,
      title: "Predictive Analytics",
      description:
        "Leverage AI-driven insights for proactive healthcare management",
    },
    {
      icon: <FaLock />,
      title: "Advanced Security",
      description:
        "Ensure patient data privacy with state-of-the-art encryption",
    },
    {
      icon: <FaClock />,
      title: "Real-time Updates",
      description:
        "Stay informed with instant notifications and live data synchronization",
    },
    {
      icon: <FaMobileAlt />,
      title: "Mobile Accessibility",
      description:
        "Access critical information on-the-go with our responsive mobile app",
    },
  ];



  return (
    <Parallax pages={5}>
      <ParallaxLayer offset={0} speed={0.5}>
        <motion.header
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="bg-gradient-to-r from-blue-600 to-indigo-800 text-white min-h-screen flex flex-col justify-center items-center text-center  flex-grow:1"
        >
          <motion.h1
            style={{ scale }} // Apply the scale transform based on scroll progress
            className="text-5xl md:text-7xl font-bold mb-6"
          >
            Clinera MDT
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
            className="text-xl md:text-2xl mb-8 max-w-3xl"
          >
            Empowering Healthcare Teams with Advanced Multi-Disciplinary
            Treatment Coordination
          </motion.p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white text-blue-600 py-3 px-8 rounded-full text-lg font-semibold flex items-center"
            onClick={() => {navigate('/login');}}
          >
            Transform Your Practice <BsArrowRight className="ml-2" />
          </motion.button>
        </motion.header>
      </ParallaxLayer>

      <ParallaxLayer offset={1} speed={0.2}>
        <section className="bg-white py-20 px-4">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-12">
              What is Clinera MDT?
            </h2>
            <div className="max-w-3xl mx-auto text-lg text-gray-700 space-y-6">
              <p>
                Clinera MDT (Multi-Disciplinary Treatment) is a cutting-edge
                healthcare management system designed to revolutionize patient
                care in modern medical facilities. It serves as a centralized
                hub for healthcare professionals, enabling seamless
                collaboration across various disciplines and departments.
              </p>
              <p>
                By integrating advanced technologies such as artificial
                intelligence, real-time data analytics, and secure cloud
                computing, Clinera MDT empowers medical teams to make informed
                decisions, streamline workflows, and ultimately enhance patient
                outcomes.
              </p>
              <p>
                Our platform is built on the principle that effective healthcare
                is a collaborative effort. It bridges communication gaps between
                specialists, nurses, administrators, and support staff, ensuring
                that every team member has access to critical patient
                information and can contribute their expertise efficiently.
              </p>
            </div>
          </div>
        </section>
      </ParallaxLayer>

      <ParallaxLayer offset={2} speed={0.1}>
        <section className="features-section bg-gray-100 py-20 px-4">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-12">
              Key Features
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  className="feature-card bg-white rounded-lg shadow-lg p-6 text-center"
                  initial={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }} // Added animation to bring the card into view
                >
                  <div className="text-4xl text-blue-600 mb-4">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-2">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      </ParallaxLayer>

      <ParallaxLayer offset={3} speed={0.5}>
        <section className="bg-blue-600 text-white py-20 px-4">
          <div className="container mx-auto flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <Lottie
                loop
                animationData={heartbeatAnimation}
                play
                style={{ width: 300, height: 300 }}
              />
            </div>
            <div className="md:w-1/2">
              <h2 className="text-4xl font-bold mb-6">
                Why Choose Clinera MDT?
              </h2>
              <ul className="space-y-4">
                {[
                  "Reduce medical errors by up to 30% through improved communication",
                  "Decrease average length of stay by 20% with optimized care coordination",
                  "Increase staff productivity by 25% via streamlined workflows",
                  "Enhance patient satisfaction scores by 40% with personalized care plans",
                  "Comply with HIPAA and other regulatory requirements effortlessly",
                  "Achieve ROI within 12 months through operational efficiencies",
                ].map((item, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className="flex items-center"
                  >
                    <BsCheck2Circle className="mr-2 text-green-400" /> {item}
                  </motion.li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </ParallaxLayer>

      <ParallaxLayer offset={4} speed={0.5}>
        <footer className="bg-gray-900 text-white py-20 px-4">
          <motion.div className="container mx-auto text-center" initial={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }} >
            <h2 className="text-3xl font-bold mb-6">
              Ready to Elevate Your Healthcare Delivery?
            </h2>
            <p className="mb-8 text-lg max-w-2xl mx-auto">
              Join leading hospitals and clinics worldwide in adopting Clinera
              MDT to transform patient care, optimize resources, and stay ahead
              in the evolving healthcare landscape.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-white text-blue-600 py-3 px-8 rounded-full text-lg font-semibold"
            >
              Schedule a Personalized Demo
            </motion.button>
            <p className="mt-8 text-sm text-gray-400">
              &copy; 2024 Clinera MDT. Revolutionizing healthcare, one patient
              at a time.
            </p>
          </motion.div>
        </footer>
      </ParallaxLayer>
    </Parallax>
  );
};

export default LandingPage;
