import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, FieldArray, FormikHelpers } from "formik";
import { motion } from "framer-motion";
import { ArrowLeft, Save, Plus, Minus, Video, MapPin } from "lucide-react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { createMeeting, updateMeeting, fetchMeeting, selectCurrentMeeting } from "../../store/slices/meetingSlice";
import { fetchUsers, selectAllUsers } from "../../store/slices/userSlice";
import { fetchPatients, selectAllPatients } from "../../store/slices/patientSlice";
import { AppDispatch, RootState } from "../../store";
import { MeetingData, Meeting } from "../../services/meetingService";

const MeetingSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  dateTime: Yup.date().required("Date and time are required"),
  duration: Yup.number().required("Duration is required").positive("Duration must be positive"),
  isVirtual: Yup.boolean().required(),
  location: Yup.string().when('isVirtual', {
    is: false,
    then: (schema) => schema.required("Location is required for in-person meetings"),
    otherwise: (schema) => schema.notRequired(),
  }),
  videoConferenceLink: Yup.string().when('isVirtual', {
    is: true,
    then: (schema) => schema.required("Video conference link is required for virtual meetings"),
    otherwise: (schema) => schema.notRequired(),
  }),
  attendeeIds: Yup.array().of(Yup.string()).min(1, "At least one attendee is required"),
  patientIds: Yup.array().of(Yup.string()),
  notes: Yup.string().nullable(),
});

const MeetingForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isEditMode = !!id;

  const currentMeeting = useSelector((state: RootState) => selectCurrentMeeting(state));
  const users = useSelector((state: RootState) => selectAllUsers(state));
  const patients = useSelector((state: RootState) => selectAllPatients(state));

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchPatients());
    if (isEditMode && id) {
      dispatch(fetchMeeting(id));
    }
  }, [dispatch, id, isEditMode]);

  const initialValues: MeetingData = isEditMode && currentMeeting
    ? {
        title: currentMeeting.attributes.title,
        dateTime: currentMeeting.attributes.dateTime,
        duration: currentMeeting.attributes.duration,
        isVirtual: currentMeeting.attributes.isVirtual,
        location: currentMeeting.attributes.location || "",
        videoConferenceLink: currentMeeting.attributes.videoConferenceLink || "",
        attendeeIds: currentMeeting.attributes.attendees.data.map(a => a.id),
        patientIds: currentMeeting.attributes.patients.data.map(p => p.id),
        notes: currentMeeting.attributes.notes || "",
      }
    : {
        title: "",
        dateTime: location.state?.startDate || new Date().toISOString(),
        duration: 60,
        isVirtual: false,
        location: "",
        videoConferenceLink: "",
        attendeeIds: [],
        patientIds: [],
        notes: "",
      };

  const handleSubmit = async (values: MeetingData, { setSubmitting }: FormikHelpers<MeetingData>) => {
    try {
      if (isEditMode && id) {
        await dispatch(updateMeeting({ id, data: values })).unwrap();
      } else {
        await dispatch(createMeeting(values)).unwrap();
      }
      navigate("/meetings");
    } catch (error) {
      console.error("Failed to save meeting:", error);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <Link
          to="/meetings"
          className="text-blue-500 hover:underline flex items-center"
        >
          <ArrowLeft size={20} className="mr-2" />
          Back to Meetings Calendar
        </Link>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 bg-gray-100 border-b">
          <h1 className="text-3xl font-bold">
            {isEditMode ? "Edit Meeting" : "Schedule New Meeting"}
          </h1>
        </div>


        <Formik
          initialValues={initialValues}
          validationSchema={MeetingSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, touched, isSubmitting, values, setFieldValue }) => (
            <Form className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                    Meeting Title
                  </label>
                  <Field
                    type="text"
                    name="title"
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                      errors.title && touched.title ? "border-red-500" : ""
                    }`}
                  />
                  {errors.title && touched.title && (
                    <div className="text-red-500 mt-1">{errors.title}</div>
                  )}
                </div>

                <div>
                  <label htmlFor="dateTime" className="block text-sm font-medium text-gray-700 mb-1">
                    Date & Time
                  </label>
                  <DatePicker
                    selected={values.dateTime ? new Date(values.dateTime) : null}
                    onChange={(date: Date | null) => setFieldValue("dateTime", date?.toISOString())}
                    showTimeSelect
                    dateFormat="Pp"
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                      errors.dateTime && touched.dateTime ? "border-red-500" : ""
                    }`}
                  />
                  {errors.dateTime && touched.dateTime && (
                    <div className="text-red-500 mt-1">{errors.dateTime}</div>
                  )}
                </div>

                <div>
                  <label htmlFor="duration" className="block text-sm font-medium text-gray-700 mb-1">
                    Duration (minutes)
                  </label>
                  <Field
                    type="number"
                    name="duration"
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                      errors.duration && touched.duration ? "border-red-500" : ""
                    }`}
                  />
                  {errors.duration && touched.duration && (
                    <div className="text-red-500 mt-1">{errors.duration}</div>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Meeting Type
                  </label>
                  <div className="mt-2 space-x-4">
                    <label className="inline-flex items-center">
                      <Field type="radio" name="isVirtual" value={false} />
                      <span className="ml-2">In-person</span>
                    </label>
                    <label className="inline-flex items-center">
                      <Field type="radio" name="isVirtual" value={true} />
                      <span className="ml-2">Virtual</span>
                    </label>
                  </div>
                </div>
              </div>

              {!values.isVirtual && (
                <div className="mt-6">
                  <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
                    Location
                  </label>
                  <Field
                    type="text"
                    name="location"
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                      errors.location && touched.location ? "border-red-500" : ""
                    }`}
                  />
                  {errors.location && touched.location && (
                    <div className="text-red-500 mt-1">{errors.location}</div>
                  )}
                </div>
              )}

              {values.isVirtual && (
                <div className="mt-6">
                  <label htmlFor="videoConferenceLink" className="block text-sm font-medium text-gray-700 mb-1">
                    Video Conference Link
                  </label>
                  <Field
                    type="text"
                    name="videoConferenceLink"
                    className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
                      errors.videoConferenceLink && touched.videoConferenceLink ? "border-red-500" : ""
                    }`}
                  />
                  {errors.videoConferenceLink && touched.videoConferenceLink && (
                    <div className="text-red-500 mt-1">{errors.videoConferenceLink}</div>
                  )}
                </div>
              )}

<div className="mt-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Attendees
              </label>
              <FieldArray name="attendeeIds">
                {({ push, remove }) => (
                  <div>
                    {values.attendeeIds && values.attendeeIds.map((attendeeId, index) => (
                      <div key={index} className="flex items-center space-x-2 mt-2">
                        <Field
                          as="select"
                          name={`attendeeIds.${index}`}
                          className="flex-grow rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                          <option value="">Select an attendee</option>
                          {users && users.map((user) => (
                            <option key={user.id} value={user.id}>
                              {`${user.firstName} ${user.lastName}`}
                            </option>
                          ))}
                        </Field>
                        <button type="button" onClick={() => remove(index)}>
                          <Minus size={20} className="text-red-500" />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => push("")}
                      className="mt-2 inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <Plus size={16} className="mr-1" />
                      Add Attendee
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>
  
            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Patients
              </label>
              <FieldArray name="patientIds">
                {({ push, remove }) => (
                  <div>
                    {values.patientIds && values.patientIds.map((patientId, index) => (
                      <div key={index} className="flex items-center space-x-2 mt-2">
                        <Field
                          as="select"
                          name={`patientIds.${index}`}
                          className="flex-grow rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                          <option value="">Select a patient</option>
                          {patients && patients.map((patient) => (
                            <option key={patient.id} value={patient.id}>
                              {`${patient.attributes.firstName} ${patient.attributes.lastName}`}
                            </option>
                          ))}
                        </Field>
                        <button type="button" onClick={() => remove(index)}>
                          <Minus size={20} className="text-red-500" />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => push("")}
                      className="mt-2 inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <Plus size={16} className="mr-1" />
                      Add Patient
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>
                <div className="mt-6">
                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
                    Notes
                  </label>
                  <Field
                    as="textarea"
                    name="notes"
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
  
                <div className="mt-8 flex justify-between items-center">
                  <div className="flex items-center">
                    {values.isVirtual ? (
                      <Video size={24} className="text-blue-500 mr-2" />
                    ) : (
                      <MapPin size={24} className="text-green-500 mr-2" />
                    )}
                    <div className="text-sm text-gray-500">
                      {values.isVirtual ? "Virtual Meeting" : "In-person Meeting"}
                    </div>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center"
                  >
                    <Save className="mr-2" size={20} />
                    {isSubmitting ? "Saving..." : "Save Meeting"}
                  </motion.button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };
  
  export default MeetingForm;
  
  
