import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer, Event, SlotInfo } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { motion } from 'framer-motion';
import { Plus, Filter, Video, MapPin } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchMeetings, selectAllMeetings, selectMeetingsLoading, selectMeetingsError } from '../../store/slices/meetingSlice';
import { AppDispatch, RootState } from '../../store';
import { Meeting } from '../../services/meetingService';

const localizer = momentLocalizer(moment);

interface MeetingEvent extends Event {
  id: string;
  title: string;
  isVirtual: boolean;
}

const MeetingsCalendar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const meetings = useSelector((state: RootState) => selectAllMeetings(state));
  const loading = useSelector((state: RootState) => selectMeetingsLoading(state));
  const error = useSelector((state: RootState) => selectMeetingsError(state));
  const [view, setView] = useState<string>('month');
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchMeetings());
  }, [dispatch]);

  const meetingEvents: MeetingEvent[] = meetings.map((meeting: Meeting) => ({
    id: meeting.id,
    title: meeting.attributes.title,
    start: new Date(meeting.attributes.dateTime),
    end: new Date(new Date(meeting.attributes.dateTime).getTime() + meeting.attributes.duration * 60000),
    isVirtual: meeting.attributes.isVirtual,
  }));

  const handleSelectEvent = (event: MeetingEvent) => {
    navigate(`/meetings/${event.id}`);
  };

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    navigate('/meetings/new', { state: { startDate: slotInfo.start, endDate: slotInfo.end } });
  };

  const eventStyleGetter = (event: MeetingEvent) => {
    const style = {
      backgroundColor: event.isVirtual ? '#3B82F6' : '#10B981',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'flex',
      alignItems: 'center',
    };
    return { style };
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Meetings Calendar</h1>
        <div className="flex space-x-4">
          <button className="px-4 py-2 border rounded-lg flex items-center">
            <Filter className="mr-2" size={20} />
            Filters
          </button>
          <Link to="/meetings/new">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center"
            >
              <Plus className="mr-2" size={20} />
              New Meeting
            </motion.button>
          </Link>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <Calendar
          localizer={localizer}
          events={meetingEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600 }}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable
          eventPropGetter={eventStyleGetter}
          views={['month', 'week', 'day']}
          onView={(newView) => setView(newView)}
          components={{
            event: (props: { event: MeetingEvent }) => (
              <div>
                <span>{props.event.title}</span>
                {props.event.isVirtual ? (
                  <Video className="ml-2 inline" size={14} />
                ) : (
                  <MapPin className="ml-2 inline" size={14} />
                )}
              </div>
            ),
          }}
        />
      </div>
      
      <div className="mt-4 flex justify-end space-x-4">
        <div className="flex items-center">
          <div className="w-4 h-4 bg-blue-500 rounded-full mr-2"></div>
          <span>Virtual Meeting</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-green-500 rounded-full mr-2"></div>
          <span>In-person Meeting</span>
        </div>
      </div>
    </div>
  );
};

export default MeetingsCalendar;