import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../index';

import { setAuthToken, removeAuthToken } from '../../services/api';
import authService ,{ LoginCredentials } from '../../services/auth';

interface AuthState {
  user: any | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  loading: false,
  error: null,
};

export const login = createAsyncThunk(
    'auth/login',
    async (credentials: LoginCredentials, { rejectWithValue }) => {
      try {
        const data = await authService.login(credentials);
        setAuthToken(data.jwt);
        return data.user;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.error?.message || 'Login failed');
      }
    }
  );
  
  export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
    removeAuthToken();
    dispatch(clearUser());
  });
  
  export const fetchCurrentUser = createAsyncThunk(
    'auth/fetchCurrentUser',
    async (_, { rejectWithValue }) => {
      try {
        const user = await authService.getCurrentUser();
        return user;
      } catch (error: any) {
        removeAuthToken();
        return rejectWithValue(error.response?.data?.error?.message || 'Failed to fetch user');
      }
    }
  );
  
  const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      clearUser: (state) => {
        state.user = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(login.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(login.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload;
        })
        .addCase(login.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        })
        .addCase(logout.fulfilled, (state) => {
          state.user = null;
        })
        .addCase(fetchCurrentUser.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchCurrentUser.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload;
        })
        .addCase(fetchCurrentUser.rejected, (state, action) => {
          state.loading = false;
          state.user = null;
          state.error = action.payload as string;
        });
    },
  });
  export const selectCurrentUser = (state: RootState) => state.auth.user;


  export const { clearUser } = authSlice.actions;
  export default authSlice.reducer;  