import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createSpecialty, updateSpecialty, DoctorSpecialty, selectAllSpecialties, SpecialtyCreateData, SpecialtyUpdateData } from '../../store/slices/doctorSpecialtySlice';
import { AppDispatch } from '../../store';

interface SpecialtyFormProps {
  specialty?: DoctorSpecialty | null;
  onClose: () => void;
}

const SpecialtySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().nullable(),
  isSubSpecialty: Yup.boolean(),
  parentSpecialtyId: Yup.number().nullable().test('conditional-required', 'Parent specialty is required for sub-specialties', function (value) {
    return !this.parent.isSubSpecialty || (this.parent.isSubSpecialty && value !== null);
  }),
});

interface FormValues {
  name: string;
  description: string | null;
  isSubSpecialty: boolean;
  parentSpecialtyId: number | null;
}

export default function SpecialtyForm({ specialty, onClose }: SpecialtyFormProps) {
  const dispatch = useDispatch<AppDispatch>();
  const allSpecialties = useSelector(selectAllSpecialties);

  const initialValues: FormValues = {
    name: specialty?.attributes.name || '',
    description: specialty?.attributes.description || '',
    isSubSpecialty: specialty?.attributes.isSubSpecialty || false,
    parentSpecialtyId: specialty?.attributes.parentSpecialty.data?.id || null,
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      const specialtyData: SpecialtyCreateData | SpecialtyUpdateData = {
        name: values.name,
        description: values.description,
        isSubSpecialty: values.isSubSpecialty,
        parentSpecialty: values.parentSpecialtyId ? { id: values.parentSpecialtyId } : null,
      };
      
      if (specialty) {
        await dispatch(updateSpecialty({ id: specialty.id, data: specialtyData }));
      } else {
        await dispatch(createSpecialty(specialtyData as SpecialtyCreateData));
      }
      onClose();
    } catch (error) {
      console.error('Failed to save specialty:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h2 className="text-lg font-semibold mb-4">{specialty ? 'Edit Specialty' : 'Create Specialty'}</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={SpecialtySchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                <Field name="name" type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" />
                <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
              </div>

              <div className="mb-4">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                <Field name="description" as="textarea" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm" />
                <ErrorMessage name="description" component="div" className="text-red-500 text-sm" />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  <Field type="checkbox" name="isSubSpecialty" className="mr-2" />
                  Is Sub-specialty
                </label>
              </div>

              {values.isSubSpecialty && (
                <div className="mb-4">
                  <label htmlFor="parentSpecialtyId" className="block text-sm font-medium text-gray-700">Parent Specialty</label>
                  <Field name="parentSpecialtyId" as="select" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
                    <option value="">Select a parent specialty</option>
                    {allSpecialties.filter(s => !s.attributes.isSubSpecialty).map((s) => (
                      <option key={s.id} value={s.id}>{s.attributes.name}</option>
                    ))}
                  </Field>
                  <ErrorMessage name="parentSpecialtyId" component="div" className="text-red-500 text-sm" />
                </div>
              )}

              <div className="flex justify-end">
                <button type="button" onClick={onClose} className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200">
                  Cancel
                </button>
                <button type="submit" className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}