import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import treatmentPlanService, { TreatmentPlan } from '../../services/treatmentPlanService';

interface TreatmentPlanState {
  treatmentPlans: TreatmentPlan[];
  currentTreatmentPlan: TreatmentPlan | null;
  loading: boolean;
  error: string | null;
}

const initialState: TreatmentPlanState = {
  treatmentPlans: [],
  currentTreatmentPlan: null,
  loading: false,
  error: null,
};

export const fetchTreatmentPlans = createAsyncThunk(
  'treatmentPlans/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      return await treatmentPlanService.getAll();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchTreatmentPlan = createAsyncThunk(
  'treatmentPlans/fetchOne',
  async (id: string, { rejectWithValue }) => {
    try {
      return await treatmentPlanService.getById(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createTreatmentPlan = createAsyncThunk(
  'treatmentPlans/create',
  async (data: TreatmentPlan, { rejectWithValue }) => {
    try {
      const response = await treatmentPlanService.create(data);
      console.log('API Response:', response);
      return response;
    } catch (error) {
      console.error('Error in createTreatmentPlan:', error);
      return rejectWithValue((error as Error).message || 'An unknown error occurred');
    }
  }
);







export const updateTreatmentPlan = createAsyncThunk(
  'treatmentPlans/update',
  async ({ id, data }: { id: string; data: Partial<TreatmentPlan> }, { rejectWithValue }) => {
    try {
      return await treatmentPlanService.update(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteTreatmentPlan = createAsyncThunk(
  'treatmentPlans/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await treatmentPlanService.delete(id);
      return id;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const treatmentPlanSlice = createSlice({
  name: 'treatmentPlans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreatmentPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTreatmentPlans.fulfilled, (state, action: PayloadAction<TreatmentPlan[]>) => {
        state.loading = false;
        state.treatmentPlans = action.payload;
      })
      .addCase(fetchTreatmentPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchTreatmentPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTreatmentPlan.fulfilled, (state, action: PayloadAction<TreatmentPlan>) => {
        state.loading = false;
        state.currentTreatmentPlan = action.payload;
      })
      .addCase(fetchTreatmentPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createTreatmentPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTreatmentPlan.fulfilled, (state, action: PayloadAction<TreatmentPlan>) => {
        state.loading = false;
        state.treatmentPlans.push(action.payload);
        state.currentTreatmentPlan = action.payload;
      })
      .addCase(createTreatmentPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        console.error('Create treatment plan rejected:', action.payload);
      })
      .addCase(updateTreatmentPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTreatmentPlan.fulfilled, (state, action: PayloadAction<TreatmentPlan>) => {
        state.loading = false;
        const index = state.treatmentPlans.findIndex((plan) => plan.id === action.payload.id);
        if (index !== -1) {
          state.treatmentPlans[index] = action.payload;
        }
        state.currentTreatmentPlan = action.payload;
      })
      .addCase(updateTreatmentPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteTreatmentPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTreatmentPlan.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.treatmentPlans = state.treatmentPlans.filter((plan) => plan.id !== action.payload);
        if (state.currentTreatmentPlan?.id === action.payload) {
          state.currentTreatmentPlan = null;
        }
      })
      .addCase(deleteTreatmentPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
     },
});

export default treatmentPlanSlice.reducer;

export const selectAllTreatmentPlans = (state: { treatmentPlans: TreatmentPlanState }) => state.treatmentPlans.treatmentPlans;
export const selectTreatmentPlanById = (state: { treatmentPlans: TreatmentPlanState }, id: string) =>
  state.treatmentPlans.treatmentPlans.find((plan) => plan.id === id);
export const selectCurrentTreatmentPlan = (state: { treatmentPlans: TreatmentPlanState }) => state.treatmentPlans.currentTreatmentPlan;
export const selectTreatmentPlansLoading = (state: { treatmentPlans: TreatmentPlanState }) => state.treatmentPlans.loading;
export const selectTreatmentPlansError = (state: { treatmentPlans: TreatmentPlanState }) => state.treatmentPlans.error;

