import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { File, Download, Trash2, Eye, RefreshCw } from 'lucide-react';
import { Document } from '../../../services/documentService';
import DicomViewer from './DicomViewer';
import { refreshDocument } from '../../../store/slices/documentSlice';
import { AppDispatch } from '../../../store';

const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:1337';

interface DocumentListProps {
  documents: Document[];
  onDelete?: (id: string) => void;
}

const DocumentList: React.FC<DocumentListProps> = ({ documents, onDelete }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedDicom, setSelectedDicom] = useState<string | null>(null);
  const [viewerError, setViewerError] = useState<string | null>(null);
  const [processingDocuments, setProcessingDocuments] = useState<Set<string>>(new Set());

  useEffect(() => {
    documents.forEach(doc => {
      if (!doc.attributes.file?.data?.attributes?.url) {
        setProcessingDocuments(prev => new Set(prev).add(doc.id));
      }
    });
  }, [documents]);

  const isDicomFile = (url: string) => {
    return url.toLowerCase().endsWith('.dcm');
  };

  const handleViewDicom = (fileUrl: string) => {
    setSelectedDicom(fileUrl);
    setViewerError(null);
  };

  const handleRefreshDocument = async (docId: string) => {
    await dispatch(refreshDocument(docId));
    setProcessingDocuments(prev => {
      const newSet = new Set(prev);
      newSet.delete(docId);
      return newSet;
    });
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold mb-4">Uploaded Documents</h2>
      {documents.length === 0 ? (
        <p className="text-gray-500">No documents uploaded yet.</p>
      ) : (
        <>
          <ul className="divide-y divide-gray-200">
            {documents.map((doc) => {
              const fileUrl = doc.attributes.file?.data?.attributes?.url
                ? `${BACKEND_URL}${doc.attributes.file.data.attributes.url}`
                : '';
              const isViewable = isDicomFile(fileUrl);
              const isProcessing = processingDocuments.has(doc.id);

              return (
                <li key={doc.id} className="py-4 flex items-center justify-between">
                  <div className="flex items-center">
                    <File className="h-6 w-6 text-gray-500 mr-3" />
                    <div>
                      <p className="text-sm font-medium text-gray-900">{doc.attributes.title}</p>
                      <p className="text-sm text-gray-500">{doc.attributes.type}</p>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    {isProcessing ? (
                      <button
                        onClick={() => handleRefreshDocument(doc.id)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <RefreshCw className="h-5 w-5" />
                      </button>
                    ) : fileUrl ? (
                      isViewable ? (
                        <button
                          onClick={() => handleViewDicom(fileUrl)}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <Eye className="h-5 w-5" />
                        </button>
                      ) : (
                        <a
                          href={fileUrl}
                          download
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <Download className="h-5 w-5" />
                        </a>
                      )
                    ) : (
                      <span className="text-gray-400">Error</span>
                    )}
                    {onDelete && (
                      <button
                        onClick={() => onDelete(doc.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
          {selectedDicom && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">DICOM Viewer</h3>
              <DicomViewer imageId={`wadouri:${selectedDicom}`} />
            </div>
          )}
          {viewerError && (
            <div className="mt-4 text-red-500">{viewerError}</div>
          )}
        </>
      )}
    </div>
  );
};

export default DocumentList;