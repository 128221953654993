import api from './api';

export interface MeetingData {
  title: string;
  dateTime: string;
  duration: number;
  isVirtual: boolean;
  location?: string;
  videoConferenceLink?: string;
  attendeeIds: string[];
  patientIds: string[];
  notes?: string;
  treatmentPlan?: any;
}

export interface Meeting {
  id: string;
  attributes: {
    title: string;
    dateTime: string;
    duration: number;
    isVirtual: boolean;
    location: string | null;
    videoConferenceLink: string | null;
    notes: string | null;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    attendees: {
      data: Array<{
        id: string;
        attributes: {
          username: string;
          email: string;
          firstName: string;
          lastName: string;
        };
      }>;
    };
    patients: {
      data: Array<{
        id: string;
        attributes: {
          externalId: string;
          firstName: string;
          lastName: string;
          dateOfBirth: string;
          gender: string;
        };
      }>;
    };
    tasks: {
      data: Array<{
        id: string;
        attributes: {
          title: string;
          status: string;
        };
      }>;
    };
  };
}




const meetingService = {
  async getAll(): Promise<Meeting[]> {
    const response = await api.get('/api/meetings?populate=*');
    return response.data.data.map((item: any) => this.transformMeeting(item));
  },

  async getById(id: string): Promise<Meeting> {
    const response = await api.get(`/api/meetings/${id}?populate=*`);
    return this.transformMeeting(response.data.data);
  },

  async create(data: MeetingData): Promise<Meeting> {
    const response = await api.post('/api/meetings', { data });
    return this.transformMeeting(response.data.data);
  },

  async update(id: string, data: Partial<MeetingData>): Promise<Meeting> {
    const response = await api.put(`/api/meetings/${id}`, { data });
    return this.transformMeeting(response.data.data);
  },

  async delete(id: string): Promise<void> {
    await api.delete(`/api/meetings/${id}`);
  },

  transformMeeting(item: any): Meeting {
    return {
      id: item.id,
      attributes: {
        ...item.attributes,
        attendees: item.attributes.attendees || { data: [] },
        patients: item.attributes.patients || { data: [] },
        tasks: item.attributes.tasks || { data: [] },
      },
    };

  },
};

export default meetingService;