import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpecialties, selectAllSpecialties, deleteSpecialty } from '../../store/slices/doctorSpecialtySlice';
import { AppDispatch, RootState } from '../../store';
import { Edit, Trash2 } from 'lucide-react';
import SpecialtyForm from './SpecialtyForm';
import { DoctorSpecialty } from '../../store/slices/doctorSpecialtySlice';

export default function SpecialtiesList() {
  const dispatch = useDispatch<AppDispatch>();
  const specialties = useSelector(selectAllSpecialties);
  const loading = useSelector((state: RootState) => state.doctorSpecialty.loading);
  const error = useSelector((state: RootState) => state.doctorSpecialty.error);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingSpecialty, setEditingSpecialty] = useState<DoctorSpecialty | null>(null);

  useEffect(() => {
    dispatch(fetchSpecialties());
  }, [dispatch]);

  const handleEdit = (specialty: DoctorSpecialty) => {
    setEditingSpecialty(specialty);
    setIsFormOpen(true);
  };

  const handleDelete = (id: number) => {
    if (window.confirm('Are you sure you want to delete this specialty?')) {
      dispatch(deleteSpecialty(id));
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Doctor Specialties</h1>
      <button
        onClick={() => setIsFormOpen(true)}
        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
      >
        Add Specialty
      </button>
      {specialties.length === 0 ? (
        <p>No specialties found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Description</th>
                <th className="px-4 py-2">Type</th>
                <th className="px-4 py-2">Parent Specialty</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {specialties.map((specialty: DoctorSpecialty) => (
                <tr key={specialty.id}>
                  <td className="border px-4 py-2">{specialty.attributes.name}</td>
                  <td className="border px-4 py-2">{specialty.attributes.description || '-'}</td>
                  <td className="border px-4 py-2">{specialty.attributes.isSubSpecialty ? 'Sub-specialty' : 'Specialty'}</td>
                  <td className="border px-4 py-2">{specialty.attributes.parentSpecialty.data?.attributes.name || '-'}</td>
                  <td className="border px-4 py-2">
                    <button onClick={() => handleEdit(specialty)} className="text-blue-500 mr-2">
                      <Edit size={18} />
                    </button>
                    <button onClick={() => handleDelete(specialty.id)} className="text-red-500">
                      <Trash2 size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {isFormOpen && (
        <SpecialtyForm
          specialty={editingSpecialty}
          onClose={() => {
            setIsFormOpen(false);
            setEditingSpecialty(null);
          }}
        />
      )}
    </div>
  );
}

