import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import meetingService, { Meeting, MeetingData } from '../../services/meetingService';
import { RootState } from '../index';

interface MeetingState {
  meetings: Meeting[];
  currentMeeting: Meeting | null;
  loading: boolean;
  error: string | null;
}

const initialState: MeetingState = {
  meetings: [],
  currentMeeting: null,
  loading: false,
  error: null,
};

export const fetchMeetings = createAsyncThunk(
  'meetings/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      return await meetingService.getAll();
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchMeeting = createAsyncThunk(
  'meetings/fetchOne',
  async (id: string, { rejectWithValue }) => {
    try {
      return await meetingService.getById(id);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createMeeting = createAsyncThunk(
  'meetings/create',
  async (data: MeetingData, { rejectWithValue }) => {
    try {
      return await meetingService.create(data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateMeeting = createAsyncThunk(
  'meetings/update',
  async ({ id, data }: { id: string; data: Partial<MeetingData> }, { rejectWithValue }) => {
    try {
      return await meetingService.update(id, data);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteMeeting = createAsyncThunk(
  'meetings/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await meetingService.delete(id);
      return id;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const meetingSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeetings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMeetings.fulfilled, (state, action: PayloadAction<Meeting[]>) => {
        state.loading = false;
        state.meetings = action.payload;
      })
      .addCase(fetchMeetings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchMeeting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMeeting.fulfilled, (state, action: PayloadAction<Meeting>) => {
        state.loading = false;
        state.currentMeeting = action.payload;
      })
      .addCase(fetchMeeting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createMeeting.fulfilled, (state, action: PayloadAction<Meeting>) => {
        state.meetings.push(action.payload);
        state.currentMeeting = action.payload;
      })
      .addCase(updateMeeting.fulfilled, (state, action: PayloadAction<Meeting>) => {
        const index = state.meetings.findIndex((meeting) => meeting.id === action.payload.id);
        if (index !== -1) {
          state.meetings[index] = action.payload;
        }
        state.currentMeeting = action.payload;
      })
      .addCase(deleteMeeting.fulfilled, (state, action: PayloadAction<string>) => {
        state.meetings = state.meetings.filter((meeting) => meeting.id !== action.payload);
        if (state.currentMeeting?.id === action.payload) {
          state.currentMeeting = null;
        }
      });
  },
});

export default meetingSlice.reducer;

export const selectAllMeetings = (state: RootState) => state.meetings.meetings;
export const selectMeetingById = (state: RootState, id: string) =>
  state.meetings.meetings.find((meeting) => meeting.id === id);
export const selectCurrentMeeting = (state: RootState) => state.meetings.currentMeeting;
export const selectMeetingsLoading = (state: RootState) => state.meetings.loading;
export const selectMeetingsError = (state: RootState) => state.meetings.error;
