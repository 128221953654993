import React from 'react';
import { motion } from 'framer-motion';
import { User, Activity, Clipboard, Calendar, CheckSquare } from 'lucide-react';
import { Patient } from '../../../store/slices/patientSlice';
import { VitalSign } from '../../../services/medicalRecordService';
import { TreatmentPlan } from '../../../services/treatmentPlanService';
import { Meeting } from '../../../services/meetingService';
import { Task } from '../../../services/taskService';

interface PatientDashboardProps {
  patient: Patient;
  vitalSigns: VitalSign[];
  treatmentPlans: TreatmentPlan[] | { data: TreatmentPlan[] };
  meetings: Meeting[];
  tasks: Task[];
}


const PatientDashboard: React.FC<PatientDashboardProps> = ({
  patient,
  vitalSigns,
  treatmentPlans,
  meetings,
  tasks,
}) => {
  const latestVitalSigns = vitalSigns && vitalSigns.length > 0 ? vitalSigns[0] : null;
  
  const activeTreatmentPlans = Array.isArray(treatmentPlans)
    ? treatmentPlans.filter(plan => 
        plan.attributes.status === 'in_progress' || plan.attributes.status === 'planned'
      )
    : treatmentPlans.data
        ? treatmentPlans.data.filter(plan => 
            plan.attributes.status === 'in_progress' || plan.attributes.status === 'planned'
          )
        : [];

  const upcomingMeetings = meetings.filter(meeting => new Date(meeting.attributes.dateTime) > new Date());
  const pendingTasks = tasks.filter(task => task.status === 'pending');


  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white p-6 rounded-lg shadow-md mb-6"
    >
      <h2 className="text-2xl font-semibold mb-4 flex items-center">
        <User className="mr-2" /> Patient Dashboard
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-blue-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Activity className="mr-2" /> Latest Vital Signs
          </h3>
          {latestVitalSigns ? (
            <>
              <p>Heart Rate: {latestVitalSigns.heartRate} bpm</p>
              <p>Blood Pressure: {latestVitalSigns.bloodPressure}</p>
              <p>Temperature: {latestVitalSigns.temperature}°F</p>
            </>
          ) : (
            <p>No vital signs recorded</p>
          )}
        </div>
        <div className="bg-green-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Clipboard className="mr-2" /> Active Treatment Plans
          </h3>
          <p>{activeTreatmentPlans.length} active plan(s)</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Calendar className="mr-2" /> Upcoming Meetings
          </h3>
          <p>{upcomingMeetings.length} upcoming meeting(s)</p>
        </div>
        <div className="bg-purple-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <CheckSquare className="mr-2" /> Pending Tasks
          </h3>
          <p>{pendingTasks.length} pending task(s)</p>
        </div>
      </div>
    </motion.div>
  );
};

export default PatientDashboard;