import api from "./api";
import { Meeting } from "./meetingService";
import { Document } from "./documentService";
import { Task } from "./taskService";
import { Patient } from "../store/slices/patientSlice";

export interface Collaborator {
  id: string;
  attributes: {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    specialties?: {
      data: Array<{
        id: number;
        attributes: {
          name: string;
          isSubSpecialty: boolean;
        };
      }>;
    };
  };
}


export interface TreatmentPlan {
  id: string;
  attributes:{
    title: string;
    description: string;
    startDate: string;
    endDate?: string | null;
    status: "planned" | "in_progress" | "completed" | "cancelled";
    // patientId: string;
    tasks: {
      data: Task[];
    };
    collaborators?: {
      data: Collaborator[];
    };

    // collaborators?: {"data": { Collaborator[]}};
    meetings?: {
      data: Meeting[];
    };
    documents?: {
      data: Document[];
    };
    patient: {
      data: Patient;
    };
    createdAt?: string
  }

 
}

// export interface TreatmentPlan extends Omit<TreatmentPlan, "patientId"> {
//   id: string;
//   collaborators: Collaborator[];
  
// }

const treatmentPlanService = {
  async getAll(): Promise<TreatmentPlan[]> {
    const response = await api.get("/api/treatment-plans?populate=*");
    console.log(response.data.data);
    // return response.data.data.map((item: any) =>
    //   this.transformTreatmentPlan(item)
    // );
    return response.data.data;
  },

  async getById(id: string): Promise<TreatmentPlan> {
    const response = await api.get(`/api/treatment-plans/${id}?populate=*`);
    return response.data.data;
  },

  async create(data: TreatmentPlan): Promise<TreatmentPlan> {
    try {
      const response = await api.post("/api/treatment-plans", {
        data: {
          ...data,
          patient: data.attributes.patient.data.id,
          collaborators: data.attributes.collaborators?.data.map((c) => ({ id: c.id })),
        },
      });
      console.log("API Response:", response);
      if (!response.data) {
        throw new Error("Invalid API response structure");
      }
      return response.data;
    } catch (error) {
      console.error("Error in create treatment plan:", error);
      throw error;
    }
  },
  

  async update(
    id: string,
    data: Partial<TreatmentPlan>
  ): Promise<TreatmentPlan> {
    const response = await api.put(`/api/treatment-plans/${id}`, {
      data: {
        ...data,
        patient: data.attributes?.patient.data.id,
        collaborators: data.attributes?.collaborators?.data.map((c) => ({ id: c.id })),
      },
    });
    return response.data.data;
  },

  async delete(id: string): Promise<void> {
    await api.delete(`/api/treatment-plans/${id}`);
  },
  // transformTreatmentPlan(item: any): TreatmentPlan {
  //   return {
  //     id: item.id,
  //     title: item.attributes.title,
  //     description: item.attributes.description,
  //     startDate: item.attributes.startDate,
  //     endDate: item.attributes.endDate,
  //     status: item.attributes.status,
  //     patient: {
  //       id: item.attributes.patient.data.id,
  //       name: `${item.attributes.patient.data.attributes.firstName} ${item.attributes.patient.data.attributes.lastName}`,
  //     },
  //     collaborators: (item.attributes.collaborators?.data || []).map(
  //       (collaborator: any) => ({
  //         id: collaborator.id,
  //         name: `${collaborator.attributes.firstName} ${collaborator.attributes.lastName}`,
  //         specialties:
  //           collaborator.attributes.specialties?.data?.map((s: any) => ({
  //             id: s.id,
  //             name: s.attributes.name,
  //             isSubSpecialty: s.attributes.isSubSpecialty,
  //           })) || [],
  //       })
  //     )
  //   };
  // },
  // transformTreatmentPlanForCreate(item: any): TreatmentPlan {
  //   return {
  //     id: item.data.id,
  //     title: item.data.attributes.title,
  //     description: item.data.attributes.description,
  //     startDate: item.data.attributes.startDate,
  //     endDate: item.data.attributes.endDate,
  //     status: item.data.attributes.status,
  //     patient: {
  //       id: item.data.attributes.patient?.data?.id || '',
  //       name: item.data.attributes.patient?.data?.attributes
  //         ? `${item.data.attributes.patient.data.attributes.firstName} ${item.data.attributes.patient.data.attributes.lastName}`
  //         : '',
  //     },
  //     collaborators: (item.data.attributes.collaborators?.data || []).map(
  //       (collaborator: any) => ({
  //         id: collaborator.id,
  //         name: `${collaborator.attributes.firstName} ${collaborator.attributes.lastName}`,
  //         specialties:
  //           collaborator.attributes.specialties?.data?.map((s: any) => ({
  //             id: s.id,
  //             name: s.attributes.name,
  //             isSubSpecialty: s.attributes.isSubSpecialty,
  //           })) || [],
  //       })
  //     ),
  //   };
  
  // },

  
};

export default treatmentPlanService;