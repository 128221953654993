import api from './api';
import { DoctorSpecialty, ApiResponse, SpecialtyCreateData, SpecialtyUpdateData } from '../store/slices/doctorSpecialtySlice';

const doctorSpecialtyService = {
  getSpecialties: async (): Promise<ApiResponse> => {
    const response = await api.get('/api/doctor-specialties?populate=*');
    return response.data;
  },

  createSpecialty: async (specialtyData: SpecialtyCreateData): Promise<{ data: DoctorSpecialty }> => {
    const response = await api.post('/api/doctor-specialties', { data: specialtyData });
    return response.data;
  },

  updateSpecialty: async (id: number, specialtyData: SpecialtyUpdateData): Promise<{ data: DoctorSpecialty }> => {
    const response = await api.put(`/api/doctor-specialties/${id}`, { data: specialtyData });
    return response.data;
  },

  deleteSpecialty: async (id: number): Promise<void> => {
    await api.delete(`/api/doctor-specialties/${id}`);
  },
};

export default doctorSpecialtyService;