import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Edit, Trash2, Plus, Search, AlertCircle, User } from 'lucide-react';
import { AppDispatch, RootState } from '../../store';
import { fetchPatients, deletePatient } from '../../store/slices/patientSlice';
import Modal from './Modal';

const PatientList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { patients, loading, error } = useSelector((state: RootState) => state.patients);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchPatients());
  }, [dispatch]);

  const handleDelete = async (id: string) => {
    try {
      await dispatch(deletePatient(id)).unwrap();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Failed to delete patient:', error);
    }
  };

  const filteredPatients = patients.filter(patient => 
    patient.attributes.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.attributes.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.attributes.externalId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-4 text-red-500 flex items-center justify-center">
        <AlertCircle className="mr-2" size={20} />
        {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Patients</h1>
        <Link to="/patients/new">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center shadow-md hover:bg-blue-600 transition-colors duration-200"
          >
            <Plus className="mr-2" size={20} />
            Add New Patient
          </motion.button>
        </Link>
      </div>

      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search patients..."
          className="w-full px-4 py-2 pl-10 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
      </div>

      {filteredPatients.length === 0 ? (
        <div className="text-center py-4 text-gray-500">No patients found</div>
      ) : (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">External ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date of Birth</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gender</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <AnimatePresence>
                {filteredPatients.map((patient) => (
                  <motion.tr
                    key={patient.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="hover:bg-gray-50 transition-colors duration-150"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">{patient.attributes.externalId}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <User className="h-10 w-10 rounded-full bg-gray-200 p-2" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {patient.attributes.firstName} {patient.attributes.lastName}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{patient.attributes.dateOfBirth}</td>
                    <td className="px-6 py-4 whitespace-nowrap capitalize">{patient.attributes.gender}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex">
                      <Link to={`/patients/${patient.id}`} className="text-indigo-600 hover:text-indigo-900 mr-4">View</Link>
                      <Link to={`/patients/${patient.id}/edit`} className="text-yellow-600 hover:text-yellow-900 mr-4">
                        <Edit size={18} />
                      </Link>
                      <button onClick={() => {
                        setDeleteId(patient.id);
                        setIsDeleteModalOpen(true);
                      }} className="text-red-600 hover:text-red-900">
                        <Trash2 size={18} />
                      </button>
                    </td>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </tbody>
          </table>
        </div>
      )}

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Confirm Deletion"
      >
        <p>Are you sure you want to delete this patient?</p>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={() => deleteId && handleDelete(deleteId)}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PatientList;

