import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import UsersList from '../User/UsersList';
import SpecialtiesList from '../DoctorSpecialty/SpecialtiesList';

export default function UserAndSpecialtyManagement() {
  const [activeTab, setActiveTab] = useState('users');

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">User and Specialty Management</h1>
      
      <Tabs defaultValue={activeTab}>
        <TabsList className="mb-4">
          <TabsTrigger value="users" onClick={() => setActiveTab('users')}>Users</TabsTrigger>
          <TabsTrigger value="specialties" onClick={() => setActiveTab('specialties')}>Specialties</TabsTrigger>
        </TabsList>
        
        <TabsContent value="users">
          <UsersList />
        </TabsContent>
        
        <TabsContent value="specialties">
          <SpecialtiesList />
        </TabsContent>
      </Tabs>
    </div>
  );
}
