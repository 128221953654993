import React from 'react';
import { Link } from 'react-router-dom';
import { User, Mail, Briefcase } from 'lucide-react';
import { motion } from 'framer-motion';
import { User as UserType } from '../../store/slices/userSlice';

interface UserCardProps {
  user: UserType;
}

const UserCard: React.FC<UserCardProps> = ({ user }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.03 }}
      className="bg-white rounded-lg shadow-md overflow-hidden"
    >
      <div className="p-6">
        <div className="flex items-center mb-4">
          <User className="h-12 w-12 text-blue-500 mr-4" />
          <div>
            <h2 className="text-xl font-semibold">{`${user.firstName} ${user.lastName}`}</h2>
            <p className="text-gray-600">{user.username}</p>
          </div>
        </div>
        <div className="mb-4">
          <p className="flex items-center text-gray-600">
            <Mail className="mr-2" size={16} />
            {user.email}
          </p>
          <p className="flex items-center text-gray-600">
            <Briefcase className="mr-2" size={16} />
            {user.userRole}
          </p>
        </div>
        <Link
          to={`/users/${user.id}`}
          className="text-blue-500 hover:text-blue-700 font-medium"
        >
          View Details
        </Link>
      </div>
    </motion.div>
  );
};

export default UserCard;