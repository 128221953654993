import api from './api';
import { User } from '../store/slices/userSlice';

const userService = {
  getUsers: async () => {
    try {
      const response = await api.get('/api/users?populate=*');
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch users');
    }
  },

  getUser: async (id: number) => {
    try {
      const response = await api.get(`/api/users/${id}?populate=*`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch user');
    }
  },

  createUser: async (userData: Omit<User, 'id' | 'confirmed' | 'blocked' | 'assignedTasks'>) => {
    try {
      const response = await api.post('/api/users', userData);
      return response.data;
    } catch (error) {
      throw new Error('Failed to create user');
    }
  },

  updateUser: async (id: string, userData: Partial<User>) => {
    try {
      const response = await api.put(`/api/users/${id}`, userData);
      return response.data;
    } catch (error) {
      throw new Error('Failed to update user');
    }
  },

  deleteUser: async (id: number) => {
    try {
      await api.delete(`/api/users/${id}`);
    } catch (error) {
      throw new Error('Failed to delete user');
    }
  },
};

export default userService;