import React, { useEffect, useRef, useState } from 'react';
import * as cornerstone from 'cornerstone-core';
import * as cornerstoneMath from 'cornerstone-math';
import * as cornerstoneTools from 'cornerstone-tools';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import Hammer from 'hammerjs';
import dicomParser from 'dicom-parser';

// Declare missing types
declare global {
  interface Window {
    cornerstoneMath: any;
  }
}

// Extend cornerstone types
declare module 'cornerstone-core' {
  export function loadImage(imageId: string): Promise<any>;
  export function displayImage(element: HTMLElement, image: any): void;
  export function enable(element: HTMLElement): void;
  export function disable(element: HTMLElement): void;
}

// Declare cornerstoneTools types
declare module 'cornerstone-tools' {
  export const external: {
    cornerstone: any;
    Hammer: any;
    cornerstoneMath: any;
  };
  export function init(): void;
  export function addToolForElement(element: HTMLElement, tool: any): void;
  export function setToolActive(toolName: string, options: any): void;
  export const WwwcTool: any;
}

// Initialize cornerstone and its tools
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
try {
    cornerstoneTools.init();
  } catch (error) {
    console.error("Error initializing cornerstoneTools:", error);
  }
  
  


  interface DicomViewerProps {
    imageId: string;
  }
  
  const DicomViewer: React.FC<DicomViewerProps> = ({ imageId }) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      let isMounted = true;
  
      const initializeCornerstone = async () => {
        try {
          if (!cornerstone || !cornerstoneTools || !cornerstoneWebImageLoader || !cornerstoneWADOImageLoader) {
            throw new Error("Cornerstone libraries not loaded properly");
          }
  
          if (elementRef.current) {
            const element = elementRef.current;
            cornerstone.enable(element);
  
            const image = await cornerstone.loadImage(imageId);
            if (isMounted) {
              cornerstone.displayImage(element, image);
              
              // Add tools
              cornerstoneTools.addTool(cornerstoneTools.ZoomTool)
              cornerstoneTools.addTool(cornerstoneTools.PanTool)
              cornerstoneTools.addTool(cornerstoneTools.WwwcTool)
              cornerstoneTools.addTool(cornerstoneTools.MagnifyTool)
              cornerstoneTools.addTool(cornerstoneTools.RotateTool)
  
              // Set initial tool
              cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1 })
            }
          }
        } catch (err) {
          if (isMounted) {
            console.error("Error initializing Cornerstone:", err);
            setError("Failed to load DICOM viewer. Please try again later.");
          }
        }
      };
  
      initializeCornerstone();
  
      return () => {
        isMounted = false;
        if (elementRef.current) {
          cornerstone.disable(elementRef.current);
        }
      };
    }, [imageId]);
  
    const handleToolChange = (toolName: string) => {
      if (elementRef.current) {
        cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });
      }
    };
  
    if (error) {
      return <div className="text-red-500">{error}</div>;
    }
  
    return (
      <div>
        <div ref={elementRef} style={{ width: '100%', height: '400px' }} />
        <div className="mt-2">
          <button onClick={() => handleToolChange('Zoom')} className="mr-2 px-2 py-1 bg-blue-500 text-white rounded">Zoom</button>
          <button onClick={() => handleToolChange('Pan')} className="mr-2 px-2 py-1 bg-blue-500 text-white rounded">Pan</button>
          <button onClick={() => handleToolChange('Wwwc')} className="mr-2 px-2 py-1 bg-blue-500 text-white rounded">Contrast</button>
          <button onClick={() => handleToolChange('Magnify')} className="mr-2 px-2 py-1 bg-blue-500 text-white rounded">Magnify</button>
          <button onClick={() => handleToolChange('Rotate')} className="px-2 py-1 bg-blue-500 text-white rounded">Rotate</button>
        </div>
      </div>
    );
  };
  
  export default DicomViewer;
    