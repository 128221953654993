import api from './api';

const patientService = {
  getPatients: async () => {
    const response = await api.get('/api/patients?populate=*');
    return response.data;
  },

  getPatient: async (id: string) => {
    const response = await api.get(`/api/patients/${id}`);
    return response.data;
  },

  createPatient: async (patientData: any) => {
    const response = await api.post('/api/patients', { data: patientData });
    return response.data;
  },

  updatePatient: async (id: string, patientData: any) => {
    const response = await api.put(`/api/patients/${id}`, { data: patientData });
    return response.data;
  },

  deletePatient: async (id: string) => {
    const response = await api.delete(`/api/patients/${id}`);
    return response.data;
  },
};

export default patientService;