import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  User,
  Calendar,
  FileText,
  Activity,
  Heart,
  Thermometer,
  Droplet,
  Plus,
  Edit,
  ArrowLeft,
  Upload,
} from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { fetchPatient } from "../../store/slices/patientSlice";
import {
  fetchMedicalRecord,
  addMedicalHistoryItem,
  addVitalSign,
  addMedicalEvent,
} from "../../store/slices/medicalRecordSlice";
import { AppDispatch, RootState } from "../../store";
import {
  MedicalHistoryItem,
  VitalSign,
  MedicalEvent,
} from "../../services/medicalRecordService";
import PatientDashboard from "./MedicalRecord/PatientDashboard";
import Modal from "./Modal";
import {
  uploadDocumentWithFile,
  deleteDocument,
  fetchDocuments,
} from "../../store/slices/documentSlice";
import DocumentList from "./MedicalRecord/DocumentList";
import DocumentUpload from "./MedicalRecord/DocumentUpload";
import { selectAllDocuments } from "../../store/slices/documentSlice";

const MedicalHistorySchema = Yup.object().shape({
  date: Yup.date().required("Date is required"),
  description: Yup.string().required("Description is required"),
  doctor: Yup.string().required("Doctor name is required"),
});

const VitalSignsSchema = Yup.object().shape({
  date: Yup.date().required("Date is required"),
  heartRate: Yup.number()
    .required("Heart rate is required")
    .positive()
    .integer(),
  bloodPressure: Yup.string()
    .required("Blood pressure is required")
    .matches(/^\d{2,3}\/\d{2,3}$/, "Invalid blood pressure format"),
  temperature: Yup.number().required("Temperature is required").positive(),
});

const MedicalEventSchema = Yup.object().shape({
  date: Yup.date().required("Date is required"),
  type: Yup.string().required("Event type is required"),
  description: Yup.string().required("Description is required"),
});

const PatientMedicalRecord: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const patient = useSelector(
    (state: RootState) => state.patients.currentPatient
  );
  const {
    medicalHistory,
    vitalSigns,
    medicalEvents,
    treatmentPlans,
    meetings,
    tasks,
    loading,
    error,
  } = useSelector((state: RootState) => state.medicalRecord);
  const documents = useSelector(selectAllDocuments);

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isVitalSignsModalOpen, setIsVitalSignsModalOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isDocumentUploadModalOpen, setIsDocumentUploadModalOpen] =
    useState(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchPatient(id));
      dispatch(fetchMedicalRecord(id));
      dispatch(fetchDocuments(id)); // Add this line to fetch documents
    }
  }, [dispatch, id]);

  const handleAddMedicalHistory = async (
    values: Omit<MedicalHistoryItem, "id">
  ) => {
    if (id) {
      await dispatch(addMedicalHistoryItem({ patientId: id, item: values }));
      setIsHistoryModalOpen(false);
    }
  };

  const handleAddVitalSigns = async (values: Omit<VitalSign, "id">) => {
    if (id) {
      await dispatch(addVitalSign({ patientId: id, vitalSign: values }));
      setIsVitalSignsModalOpen(false);
    }
  };

  const handleAddMedicalEvent = async (values: Omit<MedicalEvent, "id">) => {
    if (id) {
      await dispatch(addMedicalEvent({ patientId: id, event: values }));
      setIsEventModalOpen(false);
    }
  };

  const handleDocumentUpload = async (
    file: File,
    type: string,
    title: string
  ) => {
    if (id) {
      await dispatch(
        uploadDocumentWithFile({
          file,
          documentData: {
            title,
            type: type as
              | "report"
              | "image"
              | "lab_result"
              | "consent"
              | "other",
            patient: id,
          },
        })
      );
      setIsDocumentUploadModalOpen(false);
    }
  };

  const handleDocumentDelete = async (documentId: string) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      await dispatch(deleteDocument(documentId));
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !patient) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline">
            {" "}
            {error || "Patient not found"}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <Link
          to="/patients"
          className="text-blue-500 hover:underline flex items-center"
        >
          <ArrowLeft size={20} className="mr-2" />
          Back to Patients List
        </Link>
      </div>

      <h1 className="text-3xl font-bold mb-6">Patient Medical Record</h1>
      <PatientDashboard
        patient={patient}
        vitalSigns={vitalSigns}
        treatmentPlans={treatmentPlans}
        meetings={meetings}
        tasks={tasks}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <motion.div
          className="bg-white p-6 rounded-lg shadow-md"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <User className="mr-2" /> Patient Information
          </h2>
          <p>
            <strong>Name:</strong> {patient.attributes.firstName}{" "}
            {patient.attributes.lastName}
          </p>
          <p>
            <strong>Date of Birth:</strong>{" "}
            {new Date(patient.attributes.dateOfBirth).toLocaleDateString()}
          </p>
          <p>
            <strong>Gender:</strong> {patient.attributes.gender}
          </p>
          <p>
            <strong>Contact:</strong> {patient.attributes.contactInfo}
          </p>
        </motion.div>

        <motion.div
          className="bg-white p-6 rounded-lg shadow-md"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FileText className="mr-2" /> Medical History
          </h2>
          {medicalHistory.length > 0 ? (
            <ul className="space-y-2">
              {medicalHistory.map((item) => (
                <li key={item.id} className="border-b pb-2">
                  <p className="font-medium">
                    {new Date(item.date).toLocaleDateString()}:{" "}
                    {item.description}
                  </p>
                  <p className="text-sm text-gray-600">Doctor: {item.doctor}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No medical history available.</p>
          )}
          <button
            className="mt-4 flex items-center text-blue-500 hover:underline"
            onClick={() => setIsHistoryModalOpen(true)}
          >
            <Plus className="mr-1" size={16} /> Add Medical History Item
          </button>
        </motion.div>
      </div>

      <motion.div
        className="bg-white p-6 rounded-lg shadow-md mb-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <Activity className="mr-2" /> Vital Signs
        </h2>
        {vitalSigns.length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={vitalSigns}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(date) => new Date(date).toLocaleDateString()}
              />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip
                labelFormatter={(label) => new Date(label).toLocaleDateString()}
                formatter={(value, name) => [value, name]}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="heartRate"
                stroke="#8884d8"
                name="Heart Rate"
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="bloodPressure"
                stroke="#82ca9d"
                name="Blood Pressure"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="temperature"
                stroke="#ffc658"
                name="Temperature"
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <p className="text-gray-500">No vital signs data available.</p>
        )}
        <button
          className="mt-4 flex items-center text-blue-500 hover:underline"
          onClick={() => setIsVitalSignsModalOpen(true)}
        >
          <Plus className="mr-1" size={16} /> Add Vital Signs
        </button>
      </motion.div>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6"> */}
        <motion.div
          className="bg-white p-6 rounded-lg shadow-md mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <Calendar className="mr-2" /> Medical Events Timeline
          </h2>
          {medicalEvents.length > 0 ? (
            <ul className="border-l-2 border-blue-500">
              {medicalEvents.map((event) => (
                <li key={event.id} className="mb-4 ml-4">
                  <div className="flex items-center mb-1">
                    <div className="bg-blue-500 rounded-full h-3 w-3 -ml-[22px] mr-3"></div>
                    <span className="text-sm text-gray-600">
                      {new Date(event.date).toLocaleDateString()}
                    </span>
                  </div>
                  <p className="font-medium">
                    {event.type}: {event.description}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No medical events available.</p>
          )}
          <button
            className="mt-4 flex items-center text-blue-500 hover:underline"
            onClick={() => setIsEventModalOpen(true)}
          >
            <Plus className="mr-1" size={16} /> Add Medical Event
          </button>
        </motion.div>
        
      <motion.div
          className="bg-white p-6 rounded-lg shadow-md "
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold flex items-center">
              <FileText className="mr-2" /> Documents
            </h2>
            <button
              className="flex items-center text-blue-500 hover:underline"
              onClick={() => setIsDocumentUploadModalOpen(true)}
            >
              <Upload className="mr-1" size={16} /> Upload Document
            </button>
          </div>
          <DocumentList documents={documents} onDelete={handleDocumentDelete} />
        </motion.div>
      <Modal
        isOpen={isHistoryModalOpen}
        onClose={() => setIsHistoryModalOpen(false)}
        title="Add Medical History Item"
      >
        <Formik
          initialValues={{ date: "", description: "", doctor: "" }}
          validationSchema={MedicalHistorySchema}
          onSubmit={handleAddMedicalHistory}
        >
          {({ errors, touched }) => (
            <Form className="space-y-4">
              <div>
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date
                </label>
                <Field
                  name="date"
                  type="date"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="date"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <Field
                  name="description"
                  as="textarea"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="doctor"
                  className="block text-sm font-medium text-gray-700"
                >
                  Doctor
                </label>
                <Field
                  name="doctor"
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="doctor"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Add Item
              </button>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        isOpen={isVitalSignsModalOpen}
        onClose={() => setIsVitalSignsModalOpen(false)}
        title="Add Vital Signs"
      >
        <Formik
          initialValues={{
            date: "",
            heartRate: 0,
            bloodPressure: "",
            temperature: 0,
          }}
          validationSchema={VitalSignsSchema}
          onSubmit={handleAddVitalSigns}
        >
          {({ errors, touched }) => (
            <Form className="space-y-4">
              <div>
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date
                </label>
                <Field
                  name="date"
                  type="date"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="date"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="heartRate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Heart Rate (bpm)
                </label>
                <Field
                  name="heartRate"
                  type="number"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="heartRate"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="bloodPressure"
                  className="block text-sm font-medium text-gray-700"
                >
                  Blood Pressure (e.g., 120/80)
                </label>
                <Field
                  name="bloodPressure"
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="bloodPressure"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="temperature"
                  className="block text-sm font-medium text-gray-700"
                >
                  Temperature (°F)
                </label>
                <Field
                  name="temperature"
                  type="number"
                  step="0.1"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="temperature"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Add Vital Signs
              </button>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        isOpen={isEventModalOpen}
        onClose={() => setIsEventModalOpen(false)}
        title="Add Medical Event"
      >
        <Formik
          initialValues={{ date: "", type: "Appointment", description: "" }}
          validationSchema={MedicalEventSchema}
          onSubmit={handleAddMedicalEvent}
        >
          {({ errors, touched }) => (
            <Form className="space-y-4">
              <div>
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date
                </label>
                <Field
                  name="date"
                  type="date"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="date"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Event Type
                </label>
                <Field
                  name="type"
                  as="select"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="Appointment">Appointment</option>
                  <option value="Diagnosis">Diagnosis</option>
                  <option value="Procedure">Procedure</option>
                  <option value="Medication">Medication</option>
                </Field>
                <ErrorMessage
                  name="type"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <Field
                  name="description"
                  as="textarea"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500 text-xs mt-1"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Add Event
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        isOpen={isDocumentUploadModalOpen}
        onClose={() => setIsDocumentUploadModalOpen(false)}
        title="Upload Document"
      >
        <DocumentUpload onUpload={handleDocumentUpload} />
      </Modal>
    </div>
  );
};

export default PatientMedicalRecord;
