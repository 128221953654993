import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Edit, ArrowLeft, Calendar, User, CheckCircle, Clock, Trash2 } from 'lucide-react';
import { fetchTask, deleteTask, selectCurrentTask, selectTasksLoading, selectTasksError } from '../../store/slices/taskSlice';
import { AppDispatch, RootState } from '../../store';

const TaskDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const task = useSelector((state: RootState) => selectCurrentTask(state));
  const loading = useSelector((state: RootState) => selectTasksLoading(state));
  const error = useSelector((state: RootState) => selectTasksError(state));

  useEffect(() => {
    if (id) {
      dispatch(fetchTask(id));
    }
  }, [dispatch, id]);

  const handleDelete = async () => {
    if (id && window.confirm('Are you sure you want to delete this task?')) {
      try {
        await dispatch(deleteTask(id)).unwrap();
        navigate('/tasks');
      } catch (error) {
        console.error('Failed to delete task:', error);
      }
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (!task) {
    return <div className="text-center">Task not found</div>;
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'in_progress': return 'bg-blue-100 text-blue-800';
      case 'completed': return 'bg-green-100 text-green-800';
      case 'cancelled': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'high': return 'bg-red-100 text-red-800';
      case 'medium': return 'bg-yellow-100 text-yellow-800';
      case 'low': return 'bg-green-100 text-green-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <Link to="/tasks" className="text-blue-500 hover:underline flex items-center">
          <ArrowLeft size={20} className="mr-2" />
          Back to Tasks
        </Link>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 bg-gray-50 border-b">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold">{task.title}</h1>
            <div className="space-x-2">
              <Link to={`/tasks/${id}/edit`}>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-yellow-500 text-white px-4 py-2 rounded-lg flex items-center"
                >
                  <Edit className="mr-2" size={20} />
                  Edit Task
                </motion.button>
              </Link>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded-lg flex items-center"
              >
                <Trash2 className="mr-2" size={20} />
                Delete Task
              </motion.button>
            </div>
          </div>
          <div className="mt-4 flex items-center space-x-4">
            <span className={`px-3 py-1 rounded-full text-sm font-semibold ${getStatusColor(task.status)}`}>
              {task.status}
            </span>
            <span className={`px-3 py-1 rounded-full text-sm font-semibold ${getPriorityColor(task.priority)}`}>
              {task.priority} priority
            </span>
          </div>
        </div>

        <div className="p-6">
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Description</h2>
            <p className="text-gray-700">{task.description}</p>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <h2 className="text-xl font-semibold mb-2">Details</h2>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <Calendar className="mr-2 text-gray-500" size={20} />
                  <span>Due Date: {new Date(task.dueDate).toLocaleDateString()}</span>
                </li>
                {task.assignee && (
                  <li className="flex items-center">
                    <User className="mr-2 text-gray-500" size={20} />
                    <span>Assignee: {task.assignee.username}</span>
                  </li>
                )}
              </ul>
            </div>
            <div>
              <h2 className="text-xl font-semibold mb-2">Related Items</h2>
              <ul className="space-y-2">
                {task.treatmentPlan && (
                  <li>
                    <Link to={`/treatment-plans/${task.treatmentPlan.id}`} className="text-blue-500 hover:underline">
                      Treatment Plan: {task.treatmentPlan.title}
                    </Link>
                  </li>
                )}
                {task.meeting && (
                  <li>
                    <Link to={`/meetings/${task.meeting.id}`} className="text-blue-500 hover:underline">
                      Meeting: {task.meeting.title}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetail;