import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import doctorSpecialtyService from '../../services/doctorSpecialtyService';

export interface DoctorSpecialty {
  id: number;
  attributes: {
    name: string;
    description: string | null;
    isSubSpecialty: boolean;
    createdAt: string;
    updatedAt: string;
    parentSpecialty: {
      data: { id: number; attributes: { name: string } } | null;
    };
    subSpecialties: {
      data: { id: number; attributes: { name: string } }[];
    };
    doctors: {
      data: { id: number; attributes: { name: string } }[];
    };
  };
}

export interface ApiResponse {
  data: DoctorSpecialty[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

interface DoctorSpecialtyState {
  specialties: DoctorSpecialty[];
  loading: boolean;
  error: string | null;
}

const initialState: DoctorSpecialtyState = {
  specialties: [],
  loading: false,
  error: null,
};

export interface SpecialtyCreateData {
  name: string;
  description: string | null;
  isSubSpecialty: boolean;
  parentSpecialty: { id: number } | null;
}

export interface SpecialtyUpdateData {
  name?: string;
  description?: string | null;
  isSubSpecialty?: boolean;
  parentSpecialty?: { id: number } | null;
}

export const fetchSpecialties = createAsyncThunk<DoctorSpecialty[], void, { rejectValue: string }>(
  'doctorSpecialties/fetchSpecialties',
  async (_, { rejectWithValue }) => {
    try {
      const response = await doctorSpecialtyService.getSpecialties();
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createSpecialty = createAsyncThunk<DoctorSpecialty, SpecialtyCreateData, { rejectValue: string }>(
  'doctorSpecialties/createSpecialty',
  async (specialtyData, { rejectWithValue }) => {
    try {
      const response = await doctorSpecialtyService.createSpecialty(specialtyData);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateSpecialty = createAsyncThunk<DoctorSpecialty, { id: number; data: SpecialtyUpdateData }, { rejectValue: string }>(
  'doctorSpecialties/updateSpecialty',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await doctorSpecialtyService.updateSpecialty(id, data);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteSpecialty = createAsyncThunk<number, number, { rejectValue: string }>(
  'doctorSpecialties/deleteSpecialty',
  async (id, { rejectWithValue }) => {
    try {
      await doctorSpecialtyService.deleteSpecialty(id);
      return id;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

const doctorSpecialtySlice = createSlice({
  name: 'doctorSpecialties',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecialties.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpecialties.fulfilled, (state, action: PayloadAction<DoctorSpecialty[]>) => {
        state.loading = false;
        state.specialties = action.payload;
      })
      .addCase(fetchSpecialties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createSpecialty.fulfilled, (state, action: PayloadAction<DoctorSpecialty>) => {
        state.specialties.push(action.payload);
      })
      .addCase(updateSpecialty.fulfilled, (state, action: PayloadAction<DoctorSpecialty>) => {
        const index = state.specialties.findIndex(specialty => specialty.id === action.payload.id);
        if (index !== -1) {
          state.specialties[index] = action.payload;
        }
      })
      .addCase(deleteSpecialty.fulfilled, (state, action: PayloadAction<number>) => {
        state.specialties = state.specialties.filter(specialty => specialty.id !== action.payload);
      });
  },
});

export const selectAllSpecialties = (state: RootState) => state.doctorSpecialty.specialties;
export const selectSpecialtyById = (state: RootState, specialtyId: number) =>
  state.doctorSpecialty.specialties.find(specialty => specialty.id === specialtyId);

export default doctorSpecialtySlice.reducer;

