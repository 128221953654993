import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

export const processHL7Message = createAsyncThunk(
  'hl7/processMessage',
  async (message: string) => {
    const response = await api.post('/api//hl7-messages/process', { message });
    return response.data;
  }
);

export const generateHL7Message = createAsyncThunk(
  'hl7/generateMessage',
  async ({ patientId, messageType }: { patientId: string; messageType: string }) => {
    const response = await api.post('/api//hl7-messages/generate', { patientId, messageType });
    return response.data;
  }
);

const hl7Slice = createSlice({
  name: 'hl7',
  initialState: {
    processedMessage: null,
    generatedMessage: null,
    loading: false,
    error: null as string | null,  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processHL7Message.pending, (state) => {
        state.loading = true;
      })
      .addCase(processHL7Message.fulfilled, (state, action) => {
        state.loading = false;
        state.processedMessage = action.payload;
      })
      .addCase(processHL7Message.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(generateHL7Message.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateHL7Message.fulfilled, (state, action) => {
        state.loading = false;
        state.generatedMessage = action.payload;
      })
      .addCase(generateHL7Message.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default hl7Slice.reducer;