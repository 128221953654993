import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { motion } from "framer-motion";
import {
  createUser,
  updateUser,
  fetchUser,
  User,
} from "../../store/slices/userSlice";
import {
  fetchSpecialties,
  DoctorSpecialty,
  selectAllSpecialties,
} from "../../store/slices/doctorSpecialtySlice";
import { AppDispatch, RootState } from "../../store";
import api from "../../services/api";

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  username: Yup.string().required("Username is required"),
  userRole: Yup.string()
    .oneOf(["Admin", "Doctor", "Nurse", "Frontdesk"] as const)
    .required("Role is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  specialties: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
      isSubSpecialty: Yup.boolean().required(),
    })
  ),
});
type SpecialtyOption = {
  value: number;
  label: string;
  isSubSpecialty: boolean;
  parentSpecialty?: string;
};

interface FormValues extends Omit<User, "id"> {
  password: string;
}

export default function UserForm() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const userId = id ? parseInt(id, 10) : null;

  const allSpecialties = useSelector(selectAllSpecialties);
  const [specialtyOptions, setSpecialtyOptions] = useState<SpecialtyOption[]>(
    []
  );

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [initialValues, setInitialValues] = useState<FormValues>({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    userRole: "Frontdesk",
    password: "",
    specialties: [],
    role: 1
  });

  useEffect(() => {
    dispatch(fetchSpecialties());
    if (userId) {
      dispatch(fetchUser(userId)).then((action) => {
        if (fetchUser.fulfilled.match(action)) {
          setInitialValues({
            ...action.payload,
            password: "",
            specialties: action.payload.specialties || [],
          });
        }
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    setSpecialtyOptions(
      allSpecialties.map((specialty) => ({
        value: specialty.id,
        label: specialty.attributes.name,
        isSubSpecialty: specialty.attributes.isSubSpecialty,
        parentSpecialty:
          specialty.attributes.parentSpecialty?.data?.attributes.name,
      }))
    );
  }, [allSpecialties]);

  const fetchSpecialtiesPage = async (pageNum: number, searchQuery: string = "") => {
    try {
      const response = await api.get("/api/doctor-specialties", {
        params: {
          pagination: {
            page: pageNum,
            pageSize: 25,
          },
          populate: "*",
          filters: {
            name: {
              $containsi: searchQuery,
            },
          },
        },
      });
      const newSpecialties = response.data.data;


      // Dispatch the new specialties to the Redux store
      dispatch(fetchSpecialties.fulfilled(newSpecialties, "", undefined));
  
      setPage(pageNum);
      setHasMore(
        response.data.meta.pagination.page < response.data.meta.pagination.pageCount
      );
  
      const options: SpecialtyOption[] = newSpecialties.map(
        (specialty: DoctorSpecialty) => ({
          value: specialty.id,
          label: specialty.attributes.name,
          isSubSpecialty: specialty.attributes.isSubSpecialty,
          parentSpecialty:
            specialty.attributes.parentSpecialty?.data?.attributes.name,
        })
      );
  
      // Append new options to the existing list
      setSpecialtyOptions((prevOptions) => [...prevOptions, ...options]);
    } catch (error) {
      console.error("Error fetching specialties:", error);
    }
  };
  useEffect(() => {
    fetchSpecialtiesPage(1);
  }, [dispatch]);
  

  const handleSpecialtyInputChange = (inputValue: string) => {
    setSpecialtyOptions([]); // Reset options when searching
    setPage(1); // Reset page
    fetchSpecialtiesPage(1, inputValue); // Fetch first page with search query
  };
  const handleSpecialtyMenuScrollToBottom = () => {
    if (hasMore) {
      fetchSpecialtiesPage(page + 1);
    }
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      const userData: Omit<User, "id"> = {
        ...values,
        specialties: values.specialties || [],
      };
      if (id) {
        await dispatch(updateUser({ id, data: userData }));
      } else {
        await dispatch(createUser(userData));
      }
      navigate("/users");
    } catch (error) {
      console.error("Failed to save user:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white shadow-lg rounded-lg p-8"
      >
        <h2 className="text-2xl font-bold mb-6">
          {id ? "Edit User" : "Create New User"}
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={UserSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <Form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <Field
                    name="firstName"
                    type="text"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <Field
                    name="lastName"
                    type="text"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <Field
                    name="email"
                    type="email"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Username
                  </label>
                  <Field
                    name="username"
                    type="text"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="userRole"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Role
                  </label>
                  <Field
                    name="userRole"
                    as="select"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  >
                    <option value="Admin">Admin</option>
                    <option value="Doctor">Doctor</option>
                    <option value="Nurse">Nurse</option>
                    <option value="Frontdesk">Frontdesk</option>
                  </Field>
                  <ErrorMessage
                    name="userRole"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <Field
                    name="password"
                    type="password"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="specialties"
                  className="block text-sm font-medium text-gray-700"
                >
                  Specialties
                </label>
                <Select<SpecialtyOption, true>
  isMulti
  name="specialties"
  options={specialtyOptions}
  className="mt-1"
  classNamePrefix="select"
  value={values.specialties?.map((s) => ({
    value: s.id,
    label: s.name,
    isSubSpecialty: s.isSubSpecialty,
    parentSpecialty: specialtyOptions.find(
      (opt) => opt.value === s.id
    )?.parentSpecialty,
  }))}
  onChange={(selectedOptions) => {
    setFieldValue(
      "specialties",
      selectedOptions
        ? selectedOptions.map((option) => ({
            id: option.value,
            name: option.label,
            isSubSpecialty: option.isSubSpecialty,
          }))
        : []
    );
  }}
  onInputChange={handleSpecialtyInputChange}
  onMenuScrollToBottom={handleSpecialtyMenuScrollToBottom}
  formatOptionLabel={(option: SpecialtyOption) => (
    <div>
      {option.label}
      {option.isSubSpecialty && option.parentSpecialty && (
        <span className="ml-2 text-xs text-gray-500">
          (Subspecialty of {option.parentSpecialty})
        </span>
      )}
    </div>
  )}
/>
                <ErrorMessage
                  name="specialties"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => navigate("/users")}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {id ? "Update User" : "Create User"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </motion.div>
    </div>
  );
}
