// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
// import { motion } from 'framer-motion';
// import { Plus, Calendar, User } from 'lucide-react';

// interface Task {
//   id: string;
//   title: string;
//   description: string;
//   dueDate: string;
//   assignee: string;
//   status: 'todo' | 'in_progress' | 'done';
//   priority: 'low' | 'medium' | 'high';
// }

// const mockTasks: Task[] = [
//     { id: '1', title: 'Review patient records', description: 'Go through recent patient records and update notes', dueDate: '2023-08-15', assignee: 'Dr. Smith', status: 'todo', priority: 'high' },
//     { id: '2', title: 'Schedule follow-up appointments', description: 'Contact patients for follow-up appointments', dueDate: '2023-08-20', assignee: 'Nurse Johnson', status: 'in_progress', priority: 'medium' },
//     { id: '3', title: 'Update treatment plan', description: 'Revise treatment plan based on recent test results', dueDate: '2023-08-18', assignee: 'Dr. Brown', status: 'todo', priority: 'high' },
//     { id: '4', title: 'Prepare patient education materials', description: 'Create handouts for patient education session', dueDate: '2023-08-25', assignee: 'Nurse Davis', status: 'in_progress', priority: 'low' },
//     { id: '5', title: 'Complete medical report', description: 'Finalize and submit medical report for recent procedure', dueDate: '2023-08-17', assignee: 'Dr. Smith', status: 'done', priority: 'medium' },
//   ];
  
//   const TaskList: React.FC = () => {
//     const [tasks, setTasks] = useState<Task[]>(mockTasks);
  
//     const onDragEnd = (result: DropResult) => {
//         const { destination, source } = result;
    
//         if (!destination) {
//           return;
//         }
    
//         if (destination.droppableId === source.droppableId && destination.index === source.index) {
//           return;
//         }
    
//         const reorderedTasks = Array.from(tasks);
//         const [movedTask] = reorderedTasks.splice(source.index, 1);
//         reorderedTasks.splice(destination.index, 0, movedTask);
    
//         setTasks(reorderedTasks);
//       };
  
//     const getTasksByStatus = (status: 'todo' | 'in_progress' | 'done') => {
//       return tasks.filter(task => task.status === status);
//     };
  
//     const getPriorityColor = (priority: string) => {
//       switch (priority) {
//         case 'high': return 'bg-red-100 text-red-800';
//         case 'medium': return 'bg-yellow-100 text-yellow-800';
//         case 'low': return 'bg-green-100 text-green-800';
//         default: return 'bg-gray-100 text-gray-800';
//       }
//     };
  
//     return (
//       <div className="container mx-auto px-4 py-8">
//         <div className="flex justify-between items-center mb-6">
//           <h1 className="text-3xl font-bold text-gray-800">Tasks</h1>
//           <Link to="/tasks/new">
//             <motion.button
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.95 }}
//               className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center"
//             >
//               <Plus className="mr-2" size={20} />
//               New Task
//             </motion.button>
//           </Link>
//         </div>
  
//         <DragDropContext onDragEnd={onDragEnd}>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//             {['todo', 'in_progress', 'done'].map((status) => (
//               <div key={status} className="bg-white-100 p-4 rounded-lg">
//                 <h2 className="text-lg font-semibold mb-4 capitalize">{status.replace('_', ' ')}</h2>
//                 <Droppable droppableId={status}>
//                   {(provided) => (
//                     <div
//                       {...provided.droppableProps}
//                       ref={provided.innerRef}
//                       className="space-y-4"
//                     >
//                       {getTasksByStatus(status as 'todo' | 'in_progress' | 'done').map((task, index) => (
//                         <Draggable key={task.id} draggableId={task.id} index={index}>
//                           {(provided) => (
//                             <div
//                               ref={provided.innerRef}
//                               {...provided.draggableProps}
//                               {...provided.dragHandleProps}
//                               className="bg-white p-4 rounded shadow-md"
//                             >
//                               <h3 className="font-semibold mb-2">{task.title}</h3>
//                               <p className="text-sm text-gray-600 mb-2">{task.description}</p>
//                               <div className="flex justify-between items-center text-sm text-gray-500">
//                                 <span className="flex items-center">
//                                   <Calendar size={14} className="mr-1" />
//                                   {task.dueDate}
//                                 </span>
//                                 <span className="flex items-center">
//                                   <User size={14} className="mr-1" />
//                                   {task.assignee}
//                                 </span>
//                               </div>
//                               <div className={`mt-2 inline-block px-2 py-1 rounded-full text-xs font-semibold ${getPriorityColor(task.priority)}`}>
//                                 {task.priority}
//                               </div>
//                             </div>
//                           )}
//                         </Draggable>
//                       ))}
//                       {provided.placeholder}
//                     </div>
//                   )}
//                 </Droppable>
//               </div>
//             ))}
//           </div>
//         </DragDropContext>
//       </div>
//     );
//   };
  
//   export default TaskList;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Plus, Calendar, User, AlertCircle, CheckCircle, Clock } from 'lucide-react';
import { fetchTasks, updateTask, selectAllTasks, selectTasksLoading, selectTasksError } from '../../store/slices/taskSlice';
import { AppDispatch, RootState } from '../../store';
import { Task } from '../../services/taskService';

const TaskList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const tasks = useSelector((state: RootState) => selectAllTasks(state));
  const loading = useSelector((state: RootState) => selectTasksLoading(state));
  const error = useSelector((state: RootState) => selectTasksError(state));
  const [filter, setFilter] = useState<'all' | 'pending' | 'in_progress' | 'completed' | 'cancelled'>('all');

  useEffect(() => {
    dispatch(fetchTasks());
  }, [dispatch]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newStatus = destination.droppableId as Task['status'];
    dispatch(updateTask({ id: draggableId, data: { status: newStatus } }));
  };

  const getTasksByStatus = (status: Task['status']) => {
    return tasks.filter(task => task.status === status);
  };

  const getPriorityColor = (priority: Task['priority']) => {
    switch (priority) {
      case 'high': return 'bg-red-100 text-red-800';
      case 'medium': return 'bg-yellow-100 text-yellow-800';
      case 'low': return 'bg-green-100 text-green-800';
    }
  };

  const getStatusIcon = (status: Task['status']) => {
    switch (status) {
      case 'pending': return <Clock className="text-yellow-500" size={16} />;
      case 'in_progress': return <AlertCircle className="text-blue-500" size={16} />;
      case 'completed': return <CheckCircle className="text-green-500" size={16} />;
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Tasks</h1>
        <div className="flex items-center space-x-4">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value as typeof filter)}
            className="p-2 border rounded-md"
          >
            <option value="all">All Tasks</option>
            <option value="pending">Pending</option>
            <option value="in_progress">In Progress</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>
          <Link to="/tasks/new">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center"
            >
              <Plus className="mr-2" size={20} />
              New Task
            </motion.button>
          </Link>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {['pending', 'in_progress', 'completed', 'cancelled'].map((status) => (
            <div key={status} className="bg-gray-100 p-4 rounded-lg">
              <h2 className="text-lg font-semibold mb-4 capitalize flex items-center">
                {getStatusIcon(status as Task['status'])}
                <span className="ml-2">{status.replace('_', ' ')}</span>
              </h2>
              <Droppable droppableId={status}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-4"
                  >
                    <AnimatePresence>
                      {getTasksByStatus(status as Task['status'])
                        .filter(task => filter === 'all' || task.status === filter)
                        .map((task, index) => (
                          <Draggable key={task.id} draggableId={task.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <motion.div
                                  initial={{ opacity: 0, y: 20 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  exit={{ opacity: 0, y: -20 }}
                                  className="bg-white p-4 rounded shadow-md"
                                >
                                  <Link to={`/tasks/${task.id}`}>
                                    <h3 className="font-semibold mb-2">{task.title}</h3>
                                    <p className="text-sm text-gray-600 mb-2">{task.description}</p>
                                    <div className="flex justify-between items-center text-sm text-gray-500">
                                      <span className="flex items-center">
                                        <Calendar size={14} className="mr-1" />
                                        {new Date(task.dueDate).toLocaleDateString()}
                                      </span>
                                      {task.assignee && (
                                        <span className="flex items-center">
                                          <User size={14} className="mr-1" />
                                          {task.assignee.username}
                                        </span>
                                      )}
                                    </div>
                                    <div className={`mt-2 inline-block px-2 py-1 rounded-full text-xs font-semibold ${getPriorityColor(task.priority)}`}>
                                      {task.priority}
                                    </div>
                                  </Link>
                                </motion.div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </AnimatePresence>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};

export default TaskList;
