import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Calendar, Clock, Users, FileText, Activity, Bell, Search, Plus, ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../store';
import { fetchPatients, selectAllPatients } from '../store/slices/patientSlice';
import { fetchMeetings } from '../store/slices/meetingSlice';
import { fetchTasks, selectAllTasks } from '../store/slices/taskSlice';
import { fetchTreatmentPlans } from '../store/slices/treatmentPlanSlice';
import RecentActivity from './Dashboard/RecentActivity';

// Types
interface StatCardProps {
  icon: React.ReactNode;
  title: string;
  value: string | number;
  color: string;
}

interface NotificationProps {
  id: number;
  title: string;
  message: string;
  time: string;
  type: 'info' | 'warning' | 'success';
}

// Components
const StatCard: React.FC<StatCardProps> = ({ icon, title, value, color }) => (
  <motion.div 
    className={`bg-white rounded-lg shadow p-6 flex items-center`}
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 400, damping: 10 }}
  >
    <div className={`p-3 rounded-full ${color}`}>
      {icon}
    </div>
    <div className="ml-4">
      <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
      <p className="text-2xl font-bold text-gray-900">{value}</p>
    </div>
  </motion.div>
);

const DashboardChart: React.FC<{ data: any[] }> = ({ data }) => (
  <div className="bg-white rounded-lg shadow p-6 mb-6">
    <h2 className="text-xl font-semibold mb-4">Patient and Meeting Trends</h2>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend />
        <Line yAxisId="left" type="monotone" dataKey="patients" stroke="#3b82f6" strokeWidth={2} name="Patients" />
        <Line yAxisId="right" type="monotone" dataKey="meetings" stroke="#10b981" strokeWidth={2} name="Meetings" />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

const QuickAccessCard: React.FC = () => {
  const navigate = useNavigate();

  const handleQuickAction = (action: string) => {
    switch (action) {
      case 'schedule':
        navigate('/meetings/new');
        break;
      case 'addPatient':
        navigate('/patients/new');
        break;
      case 'createTask':
        navigate('/tasks/new');
        break;
      case 'viewAnalytics':
        // Implement analytics view navigation
        break;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold mb-4">Quick Access</h2>
      <div className="grid grid-cols-2 gap-4">
        {[
          { action: 'schedule', icon: Calendar, label: 'Schedule Meeting', color: 'bg-blue-500' },
          { action: 'addPatient', icon: Users, label: 'Add Patient', color: 'bg-green-500' },
          { action: 'createTask', icon: FileText, label: 'Create Task', color: 'bg-yellow-500' },
          { action: 'viewAnalytics', icon: Activity, label: 'View Analytics', color: 'bg-purple-500' },
        ].map((item) => (
          <motion.button
            key={item.action}
            onClick={() => handleQuickAction(item.action)}
            className={`${item.color} text-white rounded-lg p-4 flex items-center justify-center hover:opacity-90 transition-colors duration-200`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <item.icon className="h-6 w-6 mr-2" />
            {item.label}
          </motion.button>
        ))}
      </div>
    </div>
  );
};

const NotificationCenter: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  useEffect(() => {
    // Fetch notifications from backend
    // This is a mock implementation
    setNotifications([
      { id: 1, title: 'New Patient', message: 'John Doe has been added to the system', time: '2 hours ago', type: 'info' },
      { id: 2, title: 'Upcoming Meeting', message: 'MDT Review at 2:30 PM', time: '1 day ago', type: 'warning' },
      { id: 3, title: 'Task Completed', message: 'Treatment plan updated for Jane Smith', time: '2 days ago', type: 'success' },
    ]);
  }, []);

  return (
    <div className="relative">
      <motion.button
        className="p-2 rounded-full bg-gray-200 hover:bg-gray-300"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Bell className="h-6 w-6" />
      </motion.button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg z-10"
          >
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">Notifications</h3>
              {notifications.map((notification) => (
                <div key={notification.id} className={`p-3 mb-2 rounded-lg ${
                  notification.type === 'info' ? 'bg-blue-100' :
                  notification.type === 'warning' ? 'bg-yellow-100' : 'bg-green-100'
                }`}>
                  <h4 className="font-semibold">{notification.title}</h4>
                  <p className="text-sm">{notification.message}</p>
                  <p className="text-xs text-gray-500 mt-1">{notification.time}</p>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const SearchBar: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    // Implement search functionality
    // For now, let's navigate to a hypothetical search results page
    navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
  };

  return (
    <form onSubmit={handleSearch} className="relative flex-grow">
      <input
        type="text"
        placeholder="Search patients, meetings, tasks..."
        className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
    </form>
  );
};

const Dashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const patients = useSelector(selectAllPatients);
  const meetings = useSelector((state: RootState) => state.meetings.meetings);
  const tasks = useSelector(selectAllTasks);
  const treatmentPlans = useSelector((state: RootState) => state.treatmentPlans.treatmentPlans);

  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchMeetings());
    dispatch(fetchTasks());
    dispatch(fetchTreatmentPlans());
  }, [dispatch]);

  useEffect(() => {
    // Process data for the chart
    const processedData = Array.from({ length: 6 }, (_, i) => {
      const month = new Date();
      month.setMonth(month.getMonth() - i);
      return {
        name: month.toLocaleString('default', { month: 'short' }),
        patients: patients.filter(p => new Date(p.attributes.createdAt? p.attributes.createdAt: "").getMonth() === month.getMonth()).length,
        meetings: meetings.filter(m => new Date(m.attributes.dateTime).getMonth() === month.getMonth()).length,
      };
    }).reverse();
    setChartData(processedData);
  }, [patients, meetings]);

  const upcomingMeetings = meetings.filter(m => new Date(m.attributes.dateTime) > new Date()).length;
  const openTasks = tasks.filter(t => t.status !== 'completed').length;
  const activeTreatmentPlans = treatmentPlans.filter(tp => tp.attributes.status === 'in_progress').length;

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Clinera MDT Dashboard</h1>
        <div className="flex items-center space-x-4">
          {/* <SearchBar /> */}
          {/* <NotificationCenter /> */}
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <StatCard icon={<Users className="h-8 w-8 text-blue-500" />} title="Total Patients" value={patients.length} color="bg-blue-100" />
        <StatCard icon={<Calendar className="h-8 w-8 text-green-500" />} title="Upcoming Meetings" value={upcomingMeetings} color="bg-green-100" />
        <StatCard icon={<Clock className="h-8 w-8 text-yellow-500" />} title="Open Tasks" value={openTasks} color="bg-yellow-100" />
        <StatCard icon={<FileText className="h-8 w-8 text-purple-500" />} title="Active Treatment Plans" value={activeTreatmentPlans} color="bg-purple-100" />
      </div>
      
      <DashboardChart data={chartData} />
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <QuickAccessCard />
        <RecentActivity items={[...meetings, ...tasks, ...treatmentPlans]} />
      </div>
    </div>
  );
};

export default Dashboard;