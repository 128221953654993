import api from './api';
export interface Task {
  id: string;
  title: string;
  description: string;
  status: "pending" | "in_progress" | "completed";
  dueDate: string;
  priority: "low" | "medium" | "high";
  createdAt?: any;
}
export interface TaskData {
  title: string;
  description: string;
  dueDate: string;
  status: 'pending' | 'in_progress' | 'completed' | 'cancelled';
  priority: 'low' | 'medium' | 'high';
  assignee?: any;
  treatmentPlan?: any;
  meeting?: any;
  createdAt?: string;

}

export interface Task extends TaskData {
  id: string;
  // assignee?: { id: string; username: string };
  // treatmentPlan?: { id: string; title: string };
  // meeting?: { id: string; title: string };
}

const taskService = {
  async getAll(): Promise<Task[]> {
    const response = await api.get('/api/tasks?populate=*');
    return response.data.data.map((item: any) => this.transformTask(item));
  },

  async getById(id: string): Promise<Task> {
    const response = await api.get(`/api/tasks/${id}?populate=*`);
    return this.transformTask(response.data.data);
  },

  async create(data: TaskData): Promise<Task> {
    const response = await api.post('/api/tasks', { data });
    return this.transformTask(response.data.data);
  },

  async update(id: string, data: Partial<TaskData>): Promise<Task> {
    const response = await api.put(`/api/tasks/${id}`, { data });
    return this.transformTask(response.data.data);
  },

  async delete(id: string): Promise<void> {
    await api.delete(`/api/tasks/${id}`);
  },

  transformTask(item: any): Task {
    return {
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.description,
      dueDate: item.attributes.dueDate,
      createdAt: item.attributes.createdAt,
      status: item.attributes.status,
      priority: item.attributes.priority,
      assignee: item.attributes.assignee?.data ? {
        id: item.attributes.assignee.data.id,
      } : undefined,
      treatmentPlan: item.attributes.treatmentPlan?.data ? {
        id: item.attributes.treatmentPlan.data.id,
        title: item.attributes.treatmentPlan.data.attributes.title,
      } : undefined,
      meeting: item.attributes.meeting?.data ? {
        id: item.attributes.meeting.data.id,
        title: item.attributes.meeting.data.attributes.title,
      } : undefined,
    };
  },
};

export default taskService;
