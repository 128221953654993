// In UserDetail.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { fetchUser, selectUserById, User } from '../../store/slices/userSlice';
import { AppDispatch, RootState } from '../../store';
import { User as UserIcon, Mail, Briefcase, Edit } from 'lucide-react';

export default function UserDetail() {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const userId = id ? parseInt(id, 10) : null;
  const user = useSelector((state: RootState) => (userId ? selectUserById(state, userId) : null));
  const loading = useSelector((state: RootState) => state.users.loading);
  const error = useSelector((state: RootState) => state.users.error);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId));
    }
  }, [dispatch, userId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold text-gray-800">{`${user.firstName} ${user.lastName}`}</h1>
            <Link to={`/users/${user.id}/edit`}>
              <button className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center">
                <Edit className="mr-2" size={20} />
                Edit User
              </button>
            </Link>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <p className="flex items-center text-gray-600 mb-2">
                <UserIcon className="mr-2" size={20} />
                Username: {user.username}
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <Mail className="mr-2" size={20} />
                Email: {user.email}
              </p>
              <p className="flex items-center text-gray-600 mb-2">
                <Briefcase className="mr-2" size={20} />
                Role: {user.userRole}
              </p>
            </div>
            <div>
              <h2 className="text-xl font-semibold mb-2">Specialties</h2>
              {user.specialties && user.specialties.length > 0 ? (
                <ul className="list-disc list-inside">
                  {user.specialties.map((specialty) => (
                    <li key={specialty.id} className="text-gray-600">
                      {specialty.name} {specialty.isSubSpecialty ? '(Sub-specialty)' : ''}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-600">No specialties assigned</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}