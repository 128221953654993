import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Search, Filter, Calendar, User, CheckCircle, Loader, Users } from 'lucide-react';
import { fetchTreatmentPlans, selectAllTreatmentPlans, selectTreatmentPlansLoading, selectTreatmentPlansError } from '../../store/slices/treatmentPlanSlice';
import { AppDispatch, RootState } from '../../store';
import { TreatmentPlan } from '../../services/treatmentPlanService';

const TreatmentPlanList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const treatmentPlans = useSelector(selectAllTreatmentPlans);
  console.log(treatmentPlans);
  const loading = useSelector(selectTreatmentPlansLoading);
  const error = useSelector(selectTreatmentPlansError);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [sortBy, setSortBy] = useState<'title' | 'startDate'>('startDate');

  useEffect(() => {
    dispatch(fetchTreatmentPlans());
  }, [dispatch]);

  const filteredAndSortedPlans = treatmentPlans
    .filter(plan => 
      (plan.attributes.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
       (plan.attributes.patient.data.attributes.firstName +plan.attributes.patient.data.attributes.lastName).toLowerCase().includes(searchTerm.toLowerCase())) &&
      (statusFilter === 'all' || plan.attributes.status === statusFilter)
    )
    .sort((a, b) => {
      if (sortBy === 'title') {
        return a.attributes.title.localeCompare(b.attributes.title);
      } else {
        return new Date(b.attributes.startDate).getTime() - new Date(a.attributes.startDate).getTime();
      }
    });

  const getStatusColor = (status: string) => {
    switch(status) {
      case 'planned': return 'bg-yellow-100 text-yellow-800';
      case 'in_progress': return 'bg-blue-100 text-blue-800';
      case 'completed': return 'bg-green-100 text-green-800';
      case 'cancelled': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-4 text-red-500">
        Error: {error}
      </div>
    );
  }



  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Treatment Plans</h1>
        <Link to="/treatment-plans/new">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center shadow-md hover:bg-blue-600 transition-colors duration-200"
          >
            <Plus className="mr-2" size={20} />
            New Treatment Plan
          </motion.button>
        </Link>
      </div>

      <div className="mb-6 flex flex-wrap items-center space-x-4">
        <div className="relative flex-grow mb-4 md:mb-0">
          <input
            type="text"
            placeholder="Search treatment plans..."
            className="w-full px-4 py-2 border rounded-lg pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <select
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="all">All Statuses</option>
          <option value="planned">Planned</option>
          <option value="in_progress">In Progress</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>
        <select
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value as 'title' | 'startDate')}
        >
          <option value="startDate">Sort by Date</option>
          <option value="title">Sort by Title</option>
        </select>
      </div>

      <AnimatePresence>
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {filteredAndSortedPlans.map((plan) => (
            <motion.div
              key={plan.id}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              whileHover={{ scale: 1.03, boxShadow: "0 10px 20px rgba(0,0,0,0.1)" }}
              className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-200"
            >
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2">{plan.attributes.title}</h2>
                {plan.attributes.patient && (
                  <p className="text-gray-600 mb-4 flex items-center">
                    <User size={16} className="mr-2" />
                    {plan.attributes.patient.data.attributes.firstName}
                  </p>
                )}
                <p className="text-gray-600 mb-4 flex items-center">
                  <Calendar size={16} className="mr-2" />
                  {new Date(plan.attributes.startDate).toLocaleDateString()} - {plan.attributes.endDate ? new Date(plan.attributes.endDate).toLocaleDateString() : 'Ongoing'}
                </p>
                <div className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${getStatusColor(plan.attributes.status)}`}>
                  {plan.attributes.status.charAt(0).toUpperCase() + plan.attributes.status.slice(1)}
                </div>
                <div className="mt-4 flex items-center">
                  <Users size={16} className="mr-2 text-gray-500" />
                  <span className="text-sm text-gray-500">
                    {plan.attributes.collaborators ? `${plan.attributes.collaborators.data.length} collaborators` : 'No collaborators'}
                  </span>
                </div>
              </div>
              <div className="bg-gray-50 px-6 py-4">
                <Link 
                  to={`/treatment-plans/${plan.id}`} 
                  className="text-blue-500 hover:text-blue-700 font-medium flex items-center"
                >
                  View Details
                  <motion.span
                    className="ml-2"
                    initial={{ x: 0 }}
                    whileHover={{ x: 5 }}
                  >
                    →
                  </motion.span>
                </Link>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default TreatmentPlanList;

