import React, { useState, useEffect, ReactNode } from "react";

import { useNavigate } from "react-router-dom";
import { RootState } from "../store";

import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import {
  Users,
  Calendar,
  Clipboard,
  List,
  Activity,
  Search,
  Bell,
  User,
  ChevronRight,
  ChevronLeft,
  Sun,
  LogIn,
  Home,
  Moon,
} from "lucide-react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { AppDispatch } from "../store";
import { logout } from "../store/slices/authSlice";
import { useSelector } from "react-redux";

interface NavItemProps {
  to: string;
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ to, icon, label, isActive }) => (
  <Link
    to={to}
    className={`flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700 rounded-md transition-colors duration-200 ${
      isActive ? "bg-gray-700" : ""
    }`}
  >
    {icon}
    <span className="ml-2">{label}</span>
  </Link>
);
interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);


  
  const handleLogout = async () => {
    await dispatch(logout());
    navigate("/login");
  };

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const toggleDarkMode = () => setDarkMode(!darkMode);
  // Update the body class for dark mode
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const navItems = [
    { to: "/landing", icon: <Home size={20} />, label: "Home" },
    { to: "/", icon: <Activity size={20} />, label: "Dashboard" },
    { to: "/patients", icon: <Users size={20} />, label: "Patients" },
    {
      to: "/treatment-plans",
      icon: <Clipboard size={20} />,
      label: "Treatment Plans",
    },
    { to: "/meetings", icon: <Calendar size={20} />, label: "Meetings" },
    { to: "/tasks", icon: <List size={20} />, label: "Tasks" },
    { to: "/manage-users", icon: <User size={20} />, label: "Users" },
    { to: "/login", icon: <LogIn size={20} />, label: "Login" },
  ];
  
  const filteredNavItems = navItems.filter(item => {
    if (!user && (item.to === "/landing" || item.to === "/login")) {
      return true;
    }
    if (user && item.to !== "/landing" && item.to !== "/login") {
      return true;
    }
    return false;
  });

  
  return (
    <div className={`h-screen flex overflow-hidden ${darkMode ? "" : ""}`}>
      {/* Sidebar */}
      <motion.aside
        initial={{ width: sidebarOpen ? 240 : 80 }}
        animate={{ width: sidebarOpen ? 240 : 80 }}
        className="bg-gray-800 text-white"
      >
        <div className="p-4">
          <h1 className="text-2xl font-bold">Clinera MDT</h1>
        </div>
        <nav className="mt-8">
          {filteredNavItems.map((item) => (
            <NavItem
              key={item.to}
              to={item.to}
              icon={item.icon}
              label={item.label}
              isActive={location.pathname === item.to}
            />
          ))}
        </nav>
        <button
          onClick={toggleSidebar}
          className="absolute bottom-4 right-4 text-gray-300 hover:text-white"
        >
          {sidebarOpen ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
        </button>
      </motion.aside>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Header */}
        <header className="bg-white dark:bg-gray-900 shadow">
          <div className="mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
              {navItems.find((item) => item.to === location.pathname)?.label ||
                "Dashboard"}
            </h2>
            <div className="flex items-center space-x-4">
              <Search className="text-gray-500 cursor-pointer" size={20} />
              <Bell className="text-gray-500 cursor-pointer" size={20} />
              <User className="text-gray-500 cursor-pointer" size={20} />
              <button onClick={toggleDarkMode} className="text-gray-500">
                {darkMode ? <Sun size={20} /> : <Moon size={20} />}
              </button>
            </div>
          </div>
        </header>

        {/* Page content */}
        <main className="flex-1 overflow-y-auto bg-gray-100 dark:bg-gray-800">
          <div className="mx-auto py-3 sm:px-3 lg:px-8">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
