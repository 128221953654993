import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { motion } from 'framer-motion';
import { Save, ArrowLeft } from 'lucide-react';
import { createTask, updateTask, fetchTask, selectCurrentTask } from '../../store/slices/taskSlice';
import { AppDispatch, RootState } from '../../store';
import { TaskData } from '../../services/taskService';

const TaskSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  dueDate: Yup.date().required('Due date is required'),
  status: Yup.string().oneOf(['pending', 'in_progress', 'completed', 'cancelled']).required('Status is required'),
  priority: Yup.string().oneOf(['low', 'medium', 'high']).required('Priority is required'),
  assigneeId: Yup.string().nullable(),
  treatmentPlanId: Yup.string().nullable(),
  meetingId: Yup.string().nullable(),
});

const TaskForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const currentTask = useSelector((state: RootState) => selectCurrentTask(state));

  useEffect(() => {
    if (id) {
      dispatch(fetchTask(id));
    }
  }, [dispatch, id]);

  const initialValues: TaskData = {
    title: currentTask?.title || '',
    description: currentTask?.description || '',
    dueDate: currentTask?.dueDate || new Date().toISOString(),
    status: currentTask?.status || 'pending',
    priority: currentTask?.priority || 'medium',
    assignee: currentTask?.assignee?.id || '',
    treatmentPlan: currentTask?.treatmentPlan?.id || '',
    meeting: currentTask?.meeting?.id || '',
  };

  const handleSubmit = async (values: TaskData) => {
    try {
      if (id) {
        await dispatch(updateTask({ id, data: values })).unwrap();
      } else {
        await dispatch(createTask(values)).unwrap();
      }
      navigate('/tasks');
    } catch (error) {
      console.error('Failed to save task:', error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="mb-6">
          <button onClick={() => navigate('/tasks')} className="text-blue-500 hover:underline flex items-center">
            <ArrowLeft size={20} className="mr-2" />
            Back to Tasks
          </button>
        </div>
        <h1 className="text-3xl font-bold mb-6">{id ? 'Edit Task' : 'Create New Task'}</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={TaskSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">Title</label>
                <Field name="title" type="text" className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title && touched.title ? 'border-red-500' : ''}`} />
                <ErrorMessage name="title" component="div" className="text-red-500 text-xs italic" />
              </div>

              <div className="mb-4">
                <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">Description</label>
                <Field name="description" as="textarea" className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.description && touched.description ? 'border-red-500' : ''}`} />
                <ErrorMessage name="description" component="div" className="text-red-500 text-xs italic" />
              </div>

              <div className="mb-4">
                <label htmlFor="dueDate" className="block text-gray-700 text-sm font-bold mb-2">Due Date</label>
                <DatePicker
                  selected={values.dueDate ? new Date(values.dueDate) : null}
                  onChange={(date: Date | null) => setFieldValue('dueDate', date ? date.toISOString() : null)}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.dueDate && touched.dueDate ? 'border-red-500' : ''}`}
                />
                <ErrorMessage name="dueDate" component="div" className="text-red-500 text-xs italic" />
              </div>

              <div className="mb-4">
                <label htmlFor="status" className="block text-gray-700 text-sm font-bold mb-2">Status</label>
                <Field name="status" as="select" className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.status && touched.status ? 'border-red-500' : ''}`}>
                  <option value="pending">Pending</option>
                  <option value="in_progress">In Progress</option>
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </Field>
                <ErrorMessage name="status" component="div" className="text-red-500 text-xs italic" />
              </div>

              <div className="mb-4">
                <label htmlFor="priority" className="block text-gray-700 text-sm font-bold mb-2">Priority</label>
                <Field name="priority" as="select" className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.priority && touched.priority ? 'border-red-500' : ''}`}>
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </Field>
                <ErrorMessage name="priority" component="div" className="text-red-500 text-xs italic" />
              </div>

              <div className="flex items-center justify-between">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                >
                  <Save className="mr-2" size={20} />
                  {id ? 'Update Task' : 'Create Task'}
                </motion.button>
              </div>
            </Form>
          )}
        </Formik>
      </motion.div>
    </div>
  );
};

export default TaskForm;



