import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import userService from '../../services/userService';

// export interface User {
//   id: string;
//   username: string;
//   email: string;
//   provider?: string;
//   userRole: 'Admin' | 'Doctor' | 'Nurse' | 'Frontdesk';
//   confirmed: boolean;
//   blocked: boolean;
//   firstName: string;
//   lastName: string;
//   createdAt: string;
//   updatedAt: string;
//   specialties?: { id: string; name: string }[];

// }
export interface User {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  userRole: 'Admin' | 'Doctor' | 'Nurse' | 'Frontdesk';
  specialties?: { id: number; name: string; isSubSpecialty: boolean }[];
  role: number;
}




interface UserState {
  users: User[];
  currentUser: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  users: [],
  currentUser: null,
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await userService.getUsers();
      return response;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);


export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await userService.getUser(id);
      return response;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);


export const createUser = createAsyncThunk(
  'users/createUser',
  async (userData: Omit<User, 'id' | 'confirmed' | 'blocked' | 'assignedTasks'>, { rejectWithValue }) => {
    try {
      const response = await userService.createUser(userData);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ id, data }: { id: string; data: Partial<User> }, { rejectWithValue }) => {
    try {
      const response = await userService.updateUser(id, data);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id: number, { rejectWithValue }) => {
    try {
      await userService.deleteUser(id);
      return id; // Return just the id
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);


const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
      state.loading = false;
      state.users = action.payload;
    })
    .addCase(fetchUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    })
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.loading = false;
        const user = action.payload;
        const index = state.users.findIndex(u => u.id === user.id);
        if (index !== -1) {
          state.users[index] = user;
        } else {
          state.users.push(user);
        }
        state.currentUser = user;
      })
      
      
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.users.push(action.payload);
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
        const index = state.users.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.users[index] = action.payload;
        }
        if (state.currentUser && state.currentUser.id === action.payload.id) {
          state.currentUser = action.payload;
        }
      })
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<number>) => {
        state.users = state.users.filter(user => user.id !== action.payload);
        if (state.currentUser && state.currentUser.id === action.payload) {
          state.currentUser = null;
        }
      })
  },
});
export const selectAllUsers = (state: RootState) => state.users.users;
export const selectUserById = (state: RootState,userId: number) =>
  state.users.users.find(user => user.id === userId);
// export const selectUserById = (state: RootState, id: string) =>
//   state.users.users.find(user => user.id === id);

export default userSlice.reducer;