import api from './api';

export interface Document {
  id: string;
  attributes: {
    title: string;
    file: {
      data: {
        attributes: {
          url: string;
        }
      }
    };
    type: 'report' | 'image' | 'lab_result' | 'consent' | 'other';
    patient: {
      data: {
        id: string;
        attributes: {
          firstName: string;
          lastName: string;
        }
      }
    };
    uploader: {
      data: {
        id: string;
        attributes: {
          username: string;
        }
      }
    };
    version: number;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    treatmentPlan?: any;

  };
}

const documentService = {
  uploadFile: async (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('files', file);
    const response = await api.post('/api/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data[0]; // Strapi returns an array of uploaded files
  },

  createDocument: async (data: {
    title: string;
    type: 'report' | 'image' | 'lab_result' | 'consent' | 'other';
    file: number;
    patient?: string;
    treatmentPlan?: string;
    uploader?: string;
  }): Promise<Document> => {
    const response = await api.post('/api/documents', { data });
    return response.data.data;
  },
  
  

  getDocuments: async (patientId: string): Promise<Document[]> => {
    const response = await api.get(`/api/documents?filters[patient][id][$eq]=${patientId}&populate=*`);
    return response.data.data;
  },

  getDocument: async (id: string): Promise<Document> => {
    const response = await api.get(`/api/documents/${id}?populate=*`);
    return response.data.data;
  },

  // createDocument: async (data: FormData): Promise<Document> => {
  //   const response = await api.post('/api/documents', data, {
  //     headers: { 'Content-Type': 'multipart/form-data' },
  //   });
  //   return response.data.data;
  // },

  updateDocument: async (id: string, data: Partial<Omit<Document['attributes'], 'file' | 'patient' | 'uploader'>>): Promise<Document> => {
    const response = await api.put(`/api/documents/${id}`, { data });
    return response.data.data;
  },

  deleteDocument: async (id: string): Promise<void> => {
    await api.delete(`/api/documents/${id}`);
  },
};

export default documentService;